import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate,Link } from "react-router-dom";
import { adminRegisterUser } from '../Services/AuthServices';
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container
} from 'reactstrap'
const Register = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [altEmail, setAltEmail] = useState(null);
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    // useEffect(() => {
    //     if (localStorage.getItem("userData")) {
    //         navigate("/MainScreen")
    //     }

    // }, []);
    const handleText = (event) => {
        switch (event.target.name) {
            case 'firstName':
                setFirstName(event.target.value)
                break;
            case 'lastName':
                setLastName(event.target.value);
                break;
            case 'email':
                setEmail(event.target.value)
                break;
            case 'altEmail':
                setAltEmail(event.target.value)
                break;
            case 'password':
                setPassword(event.target.value)
                break;
            case 'UserName':
                setUserName(event.target.value)
                break;
        }
    }
    const registerNow = () => {

        if (
            typeof firstName !== 'undefined' &&
            firstName !== '' &&
            typeof lastName !== 'undefined' &&
            lastName !== '' &&
            typeof email !== 'undefined' &&
            email !== '' &&
            typeof password !== 'undefined' &&
            password !== '' &&
            typeof userName !== 'undefined' &&
            userName !== ''

        ) {
            adminRegisterUser(
                firstName,
                lastName,
                email,
                altEmail,
                password,
                userName
            ).then((res) => {
                if (res === true) {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setAltEmail('');
                    setPassword('');
                    setUserName('');
                    // navigate("/MainScreen")
                    navigate("/")
                }
                else {
                    setErrorModelText(res)
                    setErrorModel(true);
                }
            });
        }
        else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    return (
        <React.Fragment>
            <Container fluid >
                <Form >
                    <Row className='my-5 d-flex justify-content-center '>
                        <Col xs={5}>
                            <div>
                                <h3>Registration!</h3>
                                <h5>Welcome to Gilogi.com</h5>
                            </div>
                            <div>
                                <Label className='my-2'>First Name</Label>
                                <Input
                                    className='my-2'
                                    id="firstName"
                                    name="firstName"
                                    value={firstName}
                                    placeholder="Enter First Name"
                                    type="text"
                                    onChange={handleText}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>Last Name</Label>
                                <Input
                                    className='my-2'
                                    id="lastName"
                                    name="lastName"
                                    value={lastName}
                                    placeholder="Enter Last Name"
                                    type="text"
                                    onChange={handleText}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>Email</Label>
                                <Input
                                    className='my-2'
                                    id="exampleEmail"
                                    name="email"
                                    value={email}
                                    placeholder="something@idk.cool"
                                    type="email"
                                    onChange={handleText}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>Alt Email (optional)</Label>
                                <Input
                                    className='my-2'
                                    id="altEmail"
                                    name="altEmail"
                                    value={altEmail}
                                    placeholder="something@idk.cool"
                                    type="email"
                                    onChange={handleText}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>Password</Label>
                                <Input
                                    className='my-2'
                                    id="password"
                                    name="password"
                                    value={password}
                                    placeholder="Enter Password"
                                    type="password"
                                    onChange={handleText}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>User Name</Label>
                                <Input
                                    className='my-2'
                                    id="UserName"
                                    name="UserName"
                                    value={userName}
                                    placeholder="Enter User Name"
                                    type="text"
                                    onChange={handleText}
                                />
                            </div>

                            <div className='d-grid my-4'>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={registerNow}
                                >
                                    Register Now
                                </button>
                            </div>
                            <div className='my-4'>

                                <p>Already have an account?
                                    <Link to='/'
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    > Log In </Link>
                                </p>
                            </div>

                        </Col>
                    </Row>

                </Form>
            </Container>
            {errorModel === true ? (
                <SweetAlert
                    title={errorModelText}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        // right: "0px"
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    error
                    onConfirm={() => setErrorModel(false)}

                ></SweetAlert>
            ) : (null)}
        </React.Fragment>


    );
}

export default Register;