import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from '../Services/AuthServices';

import * as action from '../redux/action';
import { useSelector, useDispatch } from "react-redux";
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container
} from 'reactstrap'
const LogIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    // useEffect(() => {
    //     if (localStorage.getItem("userData")) {
    //         navigate("/MainScreen")
    //     }
        
    // }, []);
    const handleText = (event) => {
        switch (event.target.name) {
            case 'email':
                setEmail(event.target.value)
                break;
            case 'password':
                setPassword(event.target.value)
                break;
        }
    }
    const loginNow = async () => {
        if (
            typeof email !== 'undefined' &&
            email !== '' &&
            typeof password !== 'undefined' &&
            password !== ''
        ) {
            try {
                const result = await loginUser(email, password);
                if (result.status === 200) {
                    dispatch(action.authenticate(result.data.username));
                        localStorage.setItem(
                            "userData",
                            JSON.stringify({
                                accessToken: result.data.token,
                                userName: result.data.username,
                                role: result.data.role,
                                loginUserID: result.data.user.id
                            })
                        );
                    navigate(`/${result.data.username}`);
                    

                    setEmail('');
                    setPassword('');

                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
        else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }

    return (
        <React.Fragment>
            <Container fluid >
                <Form style={{ marginTop: "10%" }}>
                    <Row className='my-5 d-flex justify-content-center '>
                        <Col xs={5}>
                            <div>
                                <h3>LogIn</h3>
                                <h5>Welcome to Gilogi.com</h5>
                            </div>
                            <div>
                                <Label className='my-2'>Email</Label>
                                <Input
                                    className='my-2'
                                    id="email"
                                    name="email"
                                    value={email}
                                    placeholder="something@idk.cool"
                                    type="email"
                                    onChange={handleText}
                                />
                            </div>

                            <div>
                                <Label className='my-2'>Password</Label>
                                <Input
                                    className='my-2'
                                    id="password"
                                    name="password"
                                    value={password}
                                    placeholder="Enter Password"
                                    type="password"
                                    onChange={handleText}
                                />
                            </div>


                            <div className='d-grid my-4'>

                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    // onClick={navigate("/MainScreen")}
                                    onClick={loginNow}
                                >
                                    Submit
                                </button>
                            </div>
                            <div className='my-4'>
                                
                                {/* <p>don't have account?<a href="/RegisterInvited">Sign up</a></p> */}
                                
                                <p>don't have account?
                                    <Link to='/adminregister'
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    > Sign up </Link>
                                </p>
                            </div>
                            <div className='my-4'>
                                <Link to='/forgotpassword'
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >Forgot Password ? </Link>
                            </div>

                        </Col>
                    </Row>

                </Form>
            </Container>
            {errorModel === true ? (
                <SweetAlert
                    title={errorModelText}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        // right: "0px"
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    error
                    onConfirm={() => setErrorModel(false)}

                ></SweetAlert>
            ) : (null)}
        </React.Fragment>


    );
}

export default LogIn;