import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate,  } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ForgotPasswordRequest } from '../Services/AuthServices';
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container
} from 'reactstrap'
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    const forgotNow = async () => {
        if (
            typeof email !== 'undefined' &&
            email !== '' 
        ) {
            try {
                const result = await ForgotPasswordRequest(email);
                console.log('ForgotPasswordRequest', result)
                if (result.status === 200) {
                    setErrorModelText(result.data.message)
                    setErrorModel(true);
                    navigate("/changepassword", {
                        state: {
                            userMail: email
                        }
                    })
                    setEmail('');
                    

                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
        else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    return (
        <React.Fragment>
            <Container fluid >
                <Form style={{ marginTop: "12%" }}>
                    <Row className='my-5 d-flex justify-content-center '>
                        <Col xs={5}>
                            <div>
                                <h3>Forgot your password?</h3>
                                <h5>We'll email you instructions on how to reset your password</h5>
                            </div>

                            <div>
                                <Label className='my-2'>Email</Label>
                                <Input
                                    className='my-2'
                                    id="exampleEmail"
                                    name="email"
                                    placeholder="something@idk.cool"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className='d-grid my-4'>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={forgotNow}
                                    >
                                    Reset Password
                                </button>
                            </div>
                        </Col>
                    </Row>

                </Form>
                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}
            </Container>

        </React.Fragment>


    );
}

export default ForgotPassword;