import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from "react-router-dom";
import {
    userAthentication,
    registerInvitedUser,
} from '../Services/AuthServices';
import { useParams } from "react-router-dom";

import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container
} from 'reactstrap'
const InvitedRegister = () => {
    const navigate = useNavigate();
    const { code } = useParams();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    const [isShowForm, setIsShowForm] = useState(false)
    // console.log(code);
    useEffect(() => {
        invitedUserAthentication()
    }, []);

    const invitedUserAthentication = () => {
        userAthentication(code).then((res) => {
            console.log('Your Invitation Code', res)

            if (res.valid === true) {

                setIsShowForm(true)
            }
            else if (res.valid === false && res.status === false) {

                setErrorModelText(res.message)
                setErrorModel(true);
            }
            else if (res.status === true) {
                localStorage.setItem(
                    "userData",
                    JSON.stringify({
                        accessToken: res.token,
                        userName: res.username,
                        role: res.role,
                        loginUserID: res.user.id
                    })
                );
                navigate(`/${res.username}`);

           
            }
            else {

                setErrorModelText('Your Invitation Code expired')
                setErrorModel(true);
            }
        });

    }
    const handleText = (event) => {
        switch (event.target.name) {
            case 'firstName':
                setFirstName(event.target.value)
                break;
            case 'lastName':
                setLastName(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value)
                break;
            case 'UserName':
                setUserName(event.target.value)
                break;
        }
    }
    const registerNow = () => {

        if (
            typeof firstName !== 'undefined' &&
            firstName !== '' &&
            typeof lastName !== 'undefined' &&
            lastName !== '' &&
            typeof password !== 'undefined' &&
            password !== '' &&
            typeof userName !== 'undefined' &&
            userName !== ''

        ) {
            registerInvitedUser(
                firstName,
                lastName,
                password,
                userName,
                code
            ).then((res) => {
                if (res.status === true) {
                    localStorage.setItem(
                        "userData",
                        JSON.stringify({
                            accessToken: res.token,
                            userName: res.username,
                            role: res.role,
                            loginUserID: res.user.id
                        })
                    );
                    setFirstName('');
                    setLastName('');
                    setPassword('');
                    setUserName('');
                    navigate(`/${res.username}`);

                    // navigate("/")
                }
                else {
                    setErrorModelText(res.message)
                    setErrorModel(true);
                }
            });
        }
        else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    return (
        <React.Fragment>
            <Container fluid >
                {isShowForm ===true ?(
                    <Form >
                        <Row className='my-5 d-flex justify-content-center '>
                            <Col xs={5}>
                                <div>
                                    <h3>Registration!</h3>
                                    <h5>Welcome to Gilogi.com</h5>
                                </div>
                                <div>
                                    <Label className='my-2'>First Name</Label>
                                    <Input
                                        className='my-2'
                                        id="firstName"
                                        name="firstName"
                                        value={firstName}
                                        placeholder="Enter First Name"
                                        type="text"
                                        onChange={handleText}
                                    />
                                </div>
                                <div>
                                    <Label className='my-2'>Last Name</Label>
                                    <Input
                                        className='my-2'
                                        id="lastName"
                                        name="lastName"
                                        value={lastName}
                                        placeholder="Enter Last Name"
                                        type="text"
                                        onChange={handleText}
                                    />
                                </div>


                                <div>
                                    <Label className='my-2'>Password</Label>
                                    <Input
                                        className='my-2'
                                        id="password"
                                        name="password"
                                        value={password}
                                        placeholder="Enter Password"
                                        type="password"
                                        onChange={handleText}
                                    />
                                </div>
                                <div>
                                    <Label className='my-2'>User Name</Label>
                                    <Input
                                        className='my-2'
                                        id="UserName"
                                        name="UserName"
                                        value={userName}
                                        placeholder="Enter User Name"
                                        type="text"
                                        onChange={handleText}
                                    />
                                </div>
                                <div className='d-grid my-4'>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={registerNow}
                                    >
                                        Register Now
                                    </button>
                                </div>

                               
                            </Col>
                        </Row>

                    </Form>
                ) : (
                    <Row className='m-5'>
                        <Col>
                                <h4>OOPS! Something went wrong...</h4>
                        </Col>
                    </Row>
                )
                }

            </Container>
            {errorModel === true ? (
                <SweetAlert
                    title={errorModelText}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        // right: "0px"
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    error
                    onConfirm={() => setErrorModel(false)}

                ></SweetAlert>
            ) : (null)}
        </React.Fragment>


    );
}

export default InvitedRegister;