import React from 'react';
const ErrorFile =() =>{
    return(
        <div style = {{ marginTop: "15%" }}
            className="row align-items-center justify-content-center"
        >
            <div style={{ width: 'max-content', textAlign:'center' }}>
                <h1 >404</h1><br />
                <b >
                    Sorry, Page not found............!
                </b>
            </div>
               
           
       
        </div>
    )
}
export default ErrorFile