import React, { useState } from 'react';
import Axios from 'axios';
// Axios.defaults.baseURL = "${baseURL}";
// Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// Axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
// Axios.defaults.headers.post['Content-Type'] = 'application/json';
import { baseURL } from '../../../src/components/Admin/BaseURL'
export const inviteUser = async (
    inviteEmail,
    role,
    invitableId,
    invitableType,
    userName,
    accessToken
    ) => {
    const response = await fetch(
        `${baseURL}/${userName}/invite`,
        {
            
            body: JSON.stringify({ email: inviteEmail,
            role: role,
            invitable_id: invitableId,
            invitable_type: invitableType}),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const createTeamrequest = async (
    teamTitle,
    userName,
    accessToken
) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams`,
        {

            body: JSON.stringify({
                title: teamTitle,
                
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const getTeamRoles = async () => {
    try {
        const response = await Axios.get(`${baseURL}/roles-teams`);

        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }

        return response
    } catch (err) {
        throw err;
    }
}
export const getBoardWorkspaceRoles = async () => {
    try {
        const response = await Axios.get(`${baseURL}/roles-workspaces-boards`);

        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }

        return response
    } catch (err) {
        throw err;
    }
}
export const getAllTeams = async (accessToken, userName) => {
    try {
        const response = await Axios.get(`${baseURL}/${userName}/teams`, {
            headers: {
                Authorization: "Bearer" + "  " + accessToken,
                Accept: "*/*",
            },
        });
        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }
        return response
    } catch (err) {
        throw err;
    }
}
export const UpdateTeamRequest = async (accessToken, userName, teamId, teamName) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams/${teamId}`,
        {
            body: JSON.stringify({
                title: teamName,

            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const deleteTeamRequest = async (accessToken, userName, teamId) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams/${teamId}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const getTeamUsers = async (accessToken, userName, selectedTeamID) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams/${selectedTeamID}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const deleteTeamMemberRequest = async (accessToken, userName, memberId, teamID) => {
    console.log('accessToken, userName, memberId, teamID', accessToken, userName, memberId, teamID)
    const response = await fetch(
        `${baseURL}/${userName}/team-member/remove`,
        {
            body: JSON.stringify({
                member: memberId,
                team: teamID

            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const getMembersmailsRequest = async (userName, accessToken,teamID) => {
    const response = await fetch(
        `${baseURL}/${userName}/team-member/not-exists-users/${teamID}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}

export const addUser = async (memberId,teamID,role,userName,accessToken) => {
    const response = await fetch(
        `${baseURL}/${userName}/team-member`,
        {

            body: JSON.stringify({
                member_id: memberId,
                team_id: teamID,
                role: role,
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const acceptedTeamUsers = async (accessToken, userName, selectedTeamID) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams/${selectedTeamID}/accepted-users`,
        {

            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );

    const result = await response.json();
    return result;
};
export const rejectedTeamUsers = async (accessToken, userName, selectedTeamID) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams/${selectedTeamID}/rejected-users`,
        {

            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );

    const result = await response.json();
    return result;
};
export const pendingTeamUsers = async (accessToken, userName, selectedTeamID) => {
    const response = await fetch(
        `${baseURL}/${userName}/teams/${selectedTeamID}/pending-users`,
        {

            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );

    const result = await response.json();
    return result;
};
export const getEveryoneUsersRequest = async (accessToken, userName) => {
    const response = await fetch(
        `${baseURL}/${userName}/every-one-at`,
        {

            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );

    const result = await response.json();
    return result;
};
export const getAcceptedUsersAtEverone = async (accessToken, userName, ) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/accepted-users`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};

export const getPendingUsersAtEverone = async (accessToken, userName, ) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/pending-users`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const getRejectedUsersAtEverone = async (accessToken, userName, ) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/rejected-users`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};




