import React, { useEffect, useState, } from "react";
import 'react-calendar/dist/Calendar.css';
import SideBarItem from "../SideBars/SideBarItem";
import WorkspaceComponent from "../WorkspaceComponent";
import MinSideBar from "../SideBars/MinSideBar";
import "../styles.css";
import TeamSidebar from "./TeamSidebar";
import Profile from "../Profile";
import Notifications from "../Notification";
// import { useHistory } from "react-router-dom";
import { useLocation, Outlet, useParams, useNavigate } from 'react-router-dom'
const Team = (props) => {
    console.log(props)
    let { pathname } = useLocation();
    let test = useLocation();
    useEffect(() => {
        console.log('test,', test);
        localStorage.setItem(
            "team_path",
            pathname
        );

    }, [pathname]);
    let { username } = useParams();

    const [selectedNavItem, setSelectedNavItem] = useState('workspace');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedWorkspace, setSelectedWorkspace] = useState('')
    const [viewTrigger, setViewTrigger] = useState('')
    const [selectionTrigger, setSelectionTrigger] = useState()
    const [selectedBoard, setSelectedBoard] = useState('');
    const [isReloadWorkspace, setIsReloadWorkspace] = useState('');
    const [isReloadBoard, setIsReloadBoard] = useState('');
    const [isReloadTeam, setIsReloadTeam] = useState('');
    // const HomeScreenUrlRecord = localStorage.getItem("HomeScreenRecord");
    // const convertedData = JSON.parse(HomeScreenUrlRecord);
    // const { name, id } = convertedData;
    const [whichShowComponent, setWhichShowComponent] = useState('');
    const getBoardName = async (name) => {

        await setSelectedBoard(name);
    }
    const getSelectionTrigger = (name) => {
        setSelectionTrigger(name)
        setViewTrigger('')

    }
    const getNavItem = (name) => {
        setSelectedNavItem(name);
        setViewTrigger(name);
        setSelectionTrigger('')
    }
    const getSelectedWorkspaceDetail = (workspaceDetail) => {
        console.log(workspaceDetail, username)
        
        setSelectedWorkspace(workspaceDetail)
    }
    const getTeamName = (name) => {
        setSelectedTeam(name);
    }
    const getTeamComponentName = (name) => {
        setWhichShowComponent(name);
    }
    const reloadWorkspace = (param) => {
        setIsReloadWorkspace(param)
    }
    const reloadTeams = (param) => {
        setIsReloadTeam(param)

    }
    const reloadBoard = (param) => {
        setIsReloadBoard(param)
    }
    return (

        <div className="row">
            <div className=" col-3 d-flex position-fixed mt-0 p-0 min-vh-100">
                <div className="bg-dark col-2  m-0  ">
                    <MinSideBar NavItem={getNavItem} />
                </div>
                <div className="sideBar col-10 m-0 overflow-auto ">
                    <TeamSidebar
                        TeamName={getTeamName}
                        WhichShowComponent={getTeamComponentName}
                        IsReloadTeams={isReloadTeam}
                    />

                </div>
            </div>
            <Outlet />

           

        </div>
    );
};

export default Team;
