import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import MinSideBar from '../SideBars/MinSideBar';
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    acceptNotificationRequest,
    rejectNotificationRequest,
    markReadNotificationRequest,

} from '../../../Services/AdminServices/NotificationServices'
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container,
    Button,
    Spinner
} from 'reactstrap'
const Confirmation = () => {
    const navigate = useNavigate();
    const { id, username } = useParams()
    const [notificationData, setNotificationData] = useState([])
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, userName } = transformedData;
    const [errorModel, setErrorModel] = useState(false);
    const [isShowData, setIsShowData] = useState(false);

    const [errorModelText, setErrorModelText] = useState('');

    useEffect(() => {
        markAsReadNotification()
    }, [id])
    const timeFormate = (time) => {
        var date = new Date(time);

        var formattedDate = format(date, "MMMM do, yyyy H:mma");

        return formattedDate;
    }
    const acceptNotification = async (code, id) => {
        try {

            const result = await acceptNotificationRequest(accessToken, code, id);
            if (result.status === true) {

                console.log('result', result)
                // setNotificationData(result.notifications)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const rejectNotification = async (id) => {
        try {
            const result = await rejectNotificationRequest(accessToken, id);
            if (result.status === true) {
                console.log('result', result)

                // setNotificationData(result.notifications)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const markAsReadNotification = async () => {
        setIsShowData(true);
        try {
            const result = await markReadNotificationRequest(accessToken, id);
            if (result.status === true) {
                setNotificationData(result.notifications)

            }
        } catch (err) {
            setIsShowData(false);
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
        setIsShowData(false);

    }


    return (
        <>
            <div className='row'>
                <div className=" col-3 d-flex position-fixed mt-0 p-0 min-vh-100">
                    <div className="bg-dark col-2  m-0  ">
                        <MinSideBar />
                    </div>

                </div>


                {isShowData ?
                    <div className='d-flex justify-content-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    :
                    notificationData.length !== 0 ?
                        <div style={{ marginTop: "10%" }} >

                            <div className='d-flex justify-content-center mt-5'  >

                                <img src='https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'
                                    width="65" height="65" style={{ borderRadius: 60, }}
                                />

                            </div>
                            <br/>

                            <div className='d-flex justify-content-center ' >

                                {notificationData?.data.notification_type === 'invitation' ? (
                                    <>

                                        <p> {notificationData.data.owner}{' '}invited you in {notificationData.data.invited_in}</p>


                                    </>
                                ) : notificationData.data.notification_type === 'alert_notification' ? (
                                    <>

                                        <p> {notificationData.data.message}</p>


                                    </>
                                ) : (
                                    <>


                                        <p> {notificationData.data.owner}{' '}assigned you task {notificationData.data.task}</p>


                                    </>
                                )}
                            </div>
                            <div className='d-flex justify-content-center'>
                                {notificationData?.notification_status === "pending" && notificationData?.data.notification_type === 'invitation' ?
                                    (
                                        <>
                                            <div >
                                                <Button
                                                    className='btn btn-secondary m-4'
                                                    type='button'
                                                    color='black'
                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                    onClick={() => {
                                                        navigate(-1)
                                                        rejectNotification(notificationData?.id)
                                                    }}
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    className='btn btn-primary m-4 '
                                                    type='button'
                                                    color='black'
                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                    onClick={() => {
                                                        navigate(-1)
                                                        acceptNotification(notificationData?.data.invitation_code, notificationData.id)
                                                    }}
                                                >
                                                    Accept
                                                </Button>
                                            </div>
                                        </>

                                    ) :
                                    (notificationData.data.notification_type !== 'alert_notification' &&
                                        <b className='m-5'>{notificationData?.notification_status}</b>
                                    )
                                }
                            </div>
                        </div>
                        : <></>}


                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}
            </div>
        </>


    );
}

export default Confirmation;