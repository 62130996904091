import React, { useState } from 'react';
import Axios from 'axios';
// Axios.defaults.baseURL = "${baseURL}";
// Axios.defaults.baseURL = "${baseURL}";
import { baseURL } from '../../../src/components/Admin/BaseURL'
export const createWorkspacerequest = async (
    workspaceTitle,
    userName,
    accessToken
) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspaces`,
        {

            body: JSON.stringify({
                title: workspaceTitle,

            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const getWorkSpacesAdminAndOwner = async (accessToken, userName) => {
    try {
        const response = await Axios.get(`${baseURL}/${userName}/workspaces`, {

            headers: {
                Authorization: "Bearer" + "  " + accessToken,
                Accept: "*/*",
            },
        });
        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }

        return response;
    } catch (err) {
        throw err;
    }
}
export const UpdateWorkspaceTitleRequest = async (accessToken, userName, workspaceId, workspaceName) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspaces/${workspaceId}`,
        {
            body: JSON.stringify({
                title: workspaceName,

            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const UpdateWorkspaceDescriptionRequest = async (accessToken, userName, workspaceId, workspaceDescription) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspaces/${workspaceId}`,
        {
            body: JSON.stringify({
                description: workspaceDescription,

            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const deleteWorkspaceRequest = async (accessToken, userName, workspaceId) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspaces/${workspaceId}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const getSingleWorkspaceRequest = async (accessToken, userName,id) => {

    try {
        const response = await Axios.get(`${baseURL}/${userName}/workspaces/${id}`, {

            headers: {
                Authorization: "Bearer" + "  " + accessToken,
                Accept: "*/*",
            },
        });
        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }

        return response;
    } catch (err) {
        throw err;
    }
}
export const addUser = async (memberId, workspaceID, role, userName, accessToken) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspace-member`,
        {

            body: JSON.stringify({
                add_team:false,
                addable_id: memberId,
                workspace_id: workspaceID,
                role: role,
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const getMembersmailsRequest = async (userName, accessToken, workspaceID) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspace-member/not-exists-users/${workspaceID}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const deleteWorkspaceUser = async (memberId, workspaceID, userName, accessToken) => {
    const response = await fetch(
        `${baseURL}/${userName}/workspace-member/remove`,
        {

            body: JSON.stringify({
                member: memberId,
                workspace: workspaceID,
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};