import React, { useEffect } from "react";
import LogIn from './Auth/LogIn';
import ForgotPassword from './Auth/ForgotPassword';
import ChangePassword from './Auth/ChangePassword';
import AdminRegister from './Auth/AdminRegister';
import BoardComponents from "./components/Admin/BoardComponent";
import Workspace from "./components/Admin/Workspace";
import WorkspaceComponent from "./components/Admin/WorkspaceComponent";
import InvitedRegister from "./Auth/InvitedRegister";
import Team from "./components/Admin/Team/index";
import TeamDashboard from "./components/Admin/Team/TeamDashboard";
import Profile from "./components/Admin/Profile";
import Notification from "./components/Admin/Notification";
import EveryoneAt from './components/Admin/Team/EveryoneAt'
import "mdi-icons/css/materialdesignicons.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confirmation from "./components/Admin/Notification/Confirmation";
import ErrorFile from "./components/Admin/Errors/ErrorFile";
import InnerError from "./components/Admin/Errors/InnerError";
function App() {

  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Routes>

          <Route exact path="/" element={<LogIn />} />
          <Route exact path="/adminregister" element={<AdminRegister />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/changepassword" element={<ChangePassword />} />
          <Route exact path="/:username" >

            <Route exact path="" element={<Workspace />} >
              <Route exact path="workspaces" element={<WorkspaceComponent />} />
              <Route exact path="workspaces/:workspaceID" element={<WorkspaceComponent />} />
              <Route exact path="boards/:boardID" element={<BoardComponents />} />
            </Route>

            <Route exact path="" element={<Team />} >

              <Route exact path="teams/:slug" element={<EveryoneAt />} />
              <Route exact path="team/:id/:slug" element={<TeamDashboard />} />
              

            </Route>
            <Route exact path="profile/:id" element={<Profile />} />
            <Route exact path="notifications/:slug" element={<Notification />} />
            <Route exact path="notification/:id" element={<Confirmation />} />


          </Route>

          <Route exact path="/registerinvited/:code" element={<InvitedRegister />} />


          {/* <Route exact path="/RegisterInvited/:code" element={<InvitedRegister />} /> */}
          <Route exact path='/*' element={<ErrorFile />} />
        </Routes>
      </BrowserRouter>

    </div>

  );
}

export default App;
