import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate, useParams, Link, } from "react-router-dom";
import * as action from '../../../redux/action';
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';

import {
    UpdateTeamRequest,
    deleteTeamRequest,
    inviteUser,
    getTeamRoles,
    getTeamUsers,
    deleteTeamMemberRequest,
    getMembersmailsRequest,
    addUser,
    acceptedTeamUsers,
    rejectedTeamUsers,
    pendingTeamUsers
} from '../../../Services/AdminServices/TeamServices';

import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Spinner,

} from 'reactstrap'

const TeamDashboard = ({ location }) => {
    const navigate = useNavigate()
    const teamReloadID = useSelector(state => state.reducer.teamReloadID);
    const { username, id, slug } = useParams();
    const [inviteModel, setInviteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
   
    const [inviteEmail, setInviteEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState("");
    const [roles, setRoles] = useState([]);
    const [teams, setTeams] = useState([]);
    const [mails, setMails] = useState([]);
    const [successModelText, setSuccessModelText] = useState('');
    const [successModel, setSuccessModel] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [alertArray, setAlertArray] = useState({

        confirm_both: false,
        success_dlg: false,
        error_dlg: false,
        dynamic_title: '',
        dynamic_description: '',

    });
    const [allusers, setAllUsers] = useState([]);
    const [acceptedusers, setAcceptedusers] = useState([]);
    const [rejectedusers, setRejectedusers] = useState([]);
    const [pendingusers, setPendingusers] = useState([]);
    const [errorModelText, setErrorModelText] = useState('');
    const [errorModel, setErrorModel] = useState(false);
    const [reloadTeams, setReloadTeams] = useState(false);
    const [addNewMember, setAddNewMember] = useState(false);
    const [isShowAllUsers, setIsShowAllUsers] = useState(false);
    const [isShowPendingUsers, setIsShowPendingUsers] = useState(false);
    const [isShowAcceptedUsers, setIsShowAcceptedUsers] = useState(false);
    const [isShowRejectedUsers, setIsShowRejectedUsers] = useState(false);
    const [dropdownTitle, setDropdownTitle] = useState(' Add member')
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, userName } = transformedData;
    const dispatch = useDispatch();
    const [selectedMember, setSelectedMember] = useState('');

    useEffect(() => {
        // getSelectedTeamUsers()
        if (slug === 'accepted') {
            getAcceptedTeamUsers()
        }
        else if (slug === 'all') {
            getSelectedTeamUsers()
        }
        else if (slug === 'rejected') {
            getRejectedTeamUsers()
        }
        else if (slug === 'pending') {
            getPendingTeamUsers()
        }
      

    }, [id, slug]);
    useEffect(() => {
        getTeamsRoles();
        getMembersmails();
    }, []);
    const deleteTeam = async (teamId) => {
        setAlertArray({
            success_dlg: false
        })
        try {
            const result = await deleteTeamRequest(accessToken, username, teamId);
            if (result.status === true) {
                console.log(result.message);
                setReloadTeams(!reloadTeams)
                dispatch(action.reloadTeams(reloadTeams));
                navigate(`/${userName}/team/${teamReloadID}/all`)

            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }

    }
    const handleTeamtext = async (event) => {
        await setTeamName(event.target.value);
    }
    const UpdateTeam = async (teamId) => {
        try {
            const result = await UpdateTeamRequest(accessToken, username, teamId, teamName);
            if (result.status === true) {

                setReloadTeams(!reloadTeams)
                dispatch(action.updateTeamLocally(teamId, teamName));
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const handleSelectedRole = async (role) => {
        await setSelectedRole(role);
    }
    const handleTextes = (event) => {
        switch (event.target.name) {
            case 'InviteEmail':
                setInviteEmail(event.target.value)
                break;
        }
    }
    const roleOption = roles.map((item) => ({
        label: item.name, value: item.id
    }));
    const getTeamsRoles = async () => {
        try {
            const result = await getTeamRoles();
            if (result.status === 200) {
                setRoles(result.data);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const InviteNow = async () => {
        setInviteModel(!inviteModel)
        if (
            typeof inviteEmail !== 'undefined' &&
            inviteEmail !== '' &&
            typeof selectedRole.value !== 'undefined' &&
            selectedRole.value !== ''
        ) {
            const result = await inviteUser(
                inviteEmail,
                selectedRole.value,
                id,
                'team',
                username,
                accessToken
            );
            if (result.status == true) {
                navigate(`/${username}/team/${id}/all`);
                getSelectedTeamUsers()

                setInviteEmail('');
                setSuccessModelText(result.message)
                setSuccessModel(true);
            }
            else {

                setErrorModelText(result.message)
                setErrorModel(true);
            }

        } else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    const getSelectedTeamUsers = async () => {
        try {
            setIsShowAllUsers(true)
            const result = await getTeamUsers(accessToken, username, id);
            if (result.status === true) {
                setTeamName(result.team.title)
                setAllUsers(result.all);
                setIsShowAllUsers(false)


            }

        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getAcceptedTeamUsers = async () => {
        try {
            setIsShowAcceptedUsers(true)

            const result = await acceptedTeamUsers(accessToken, username, id);
            console.log('getAcceptedTeamUsers', result)
            if (result.status === true) {
                console.log('getAcceptedTeamUsers', result)
                setAcceptedusers(result.accepted)

                setIsShowAcceptedUsers(false)


            }

        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getRejectedTeamUsers = async () => {
        try {
            setIsShowRejectedUsers(true)

            const result = await rejectedTeamUsers(accessToken, username, id);
            if (result.status === true) {
                console.log('getRejectedTeamUsers', result)
                setRejectedusers(result.rejected)
                setIsShowRejectedUsers(false)
            }

        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getPendingTeamUsers = async () => {
        try {
            setIsShowPendingUsers(true)

            const result = await pendingTeamUsers(accessToken, username, id);
            if (result.status === true) {
                setPendingusers(result.pending)
                console.log('getPendingTeamUsers', result)
                setIsShowPendingUsers(false)


            }

        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const deleteTeamMember = async (memberId) => {

        try {
            const result = await deleteTeamMemberRequest(accessToken, username, memberId, id);
            if (result.status === true) {

                if (slug === 'accepted') {
                    getAcceptedTeamUsers()
                }
                else if (slug === 'all') {
                    getSelectedTeamUsers()
                }
                else if (slug === 'rejected') {
                    getRejectedTeamUsers()
                }
                else if (slug === 'pending') {
                    getPendingTeamUsers()
                }
                // getSelectedTeamUsers();
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }

    }
    const getMembersmails = async () => {
        try {
            const result = await getMembersmailsRequest(username, accessToken,id);
            if (result.status === true) {

                setMails(result.users);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const addNewMemberBymail = async () => {
          setAddModel(false)
        const result = await addUser(
            selectedMember.value,
            id,
            selectedRole.value,
            username,
            accessToken
        );
        if (result.status == true) {
            setSelectedMember('');
            setSelectedRole('')
            setDropdownTitle('Add member')
            setSuccessModelText(result.message)
            setSuccessModel(true);
            navigate(`/${username}/team/${id}/all`);
            getMembersmails()
            getSelectedTeamUsers()
        }
        else {

            setErrorModelText(result.message)
            setErrorModel(true);
        }


    }
    const memberOptions = mails.map((item) => ({
        label: item.email, value: item.id,

    }));
 
    const handleMembers = (e) => {
        setSelectedMember(e)
        // addNewMemberBymail(e.value)
    }

    return (
        <div className="container-fluid">
            <div className="col-9 offset-3 mt-2">


                <div className='d-flex justify-content-between'>
                    <div >
                        <Input
                            style={{ border: 'white', fontSize: 20, width: 500 }}
                            value={teamName}
                            onChange={(e) => handleTeamtext(e)}
                            type="text"
                            onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                            onMouseOut={(e) => e.target.style.backgroundColor = ''}
                            onBlur={() => UpdateTeam(id)}
                        />
                    </div>
                    <div>
                        <Button
                            type='submit'
                            color='white'
                            onClick={() => setInviteModel(true)}
                            className='text-primary'
                        >
                            <span className='px-2'>
                                <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                                    <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            Invite  member
                        </Button>
                    </div>
                    <div>
                        <Button
                            type='submit'
                            color='white'
                            onClick={() => setAddModel(true)}
                            className='text-primary'
                        >
                            <span className='px-2'>
                                <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                                    <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                    </path>
                                </svg>
                            </span>
                            Add member
                        </Button>
                    </div>
                    <div>
                        <Link
                            to='#'
                            onClick={() => setAlertArray({ confirm_both: true, })}
                            className='text-danger mx-5 my-3'
                        >
                            <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                        </Link>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            style={{ borderColor: slug === 'all' ? 'blue' : '' }}
                            type="button"
                            onClick={() => {
                                navigate(`/${username}/team/${id}/all`)
                            }}
                        >
                            All
                        </button>
                    </div>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            style={{ borderColor: slug === 'accepted' ? 'blue' : '' }}
                            type="button"
                            onClick={() => {
                                navigate(`/${username}/team/${id}/accepted`)
                            }}
                        >
                            Accepted
                        </button>
                    </div>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            type="button"
                            style={{ borderColor: slug === 'rejected' ? 'blue' : '' }}
                            onClick={() => {
                                navigate(`/${username}/team/${id}/rejected`)
                            }}
                        >
                            Rejected
                        </button>
                    </div>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            style={{ borderColor: slug === 'pending' ? 'blue' : '' }}
                            type="button"
                            onClick={() => {
                                navigate(`/${username}/team/${id}/pending`)
                            }}
                        >
                            Pending
                        </button>
                    </div>
                </div>
                <table className="mx-3 w-100 mt-5" >
                    <thead>
                        <tr className='border-bottom border-secondary'>
                            <th className='py-4' >Email</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            slug === 'all' ? (
                                isShowAllUsers === false ? (
                                    allusers.map((item, index) => (
                                        <tr className='border-bottom border-secondary ' key={index}

                                            style={{ cursor: 'pointer' }}

                                        >
                                            <td className='text-primary py-4'
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >{item.email} </td>
                                            <td className='text-primary py-4' >{item.pivot.invitation_status} </td>
                                            <td >
                                                <Link
                                                    to='#'
                                                    onClick={() => deleteTeamMember(item.id)}
                                                    className='text-danger mx-5 my-3'
                                                >
                                                    <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <div className='d-flex justify-content-center'>

                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                )
                            ) : slug === 'accepted' ? (
                                isShowAcceptedUsers === false ? (
                                    acceptedusers.map((item, index) => (

                                        <tr className='border-bottom border-secondary ' key={index}
                                            style={{ cursor: 'pointer' }}

                                        >
                                            <td className='text-primary py-4'
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >{item.email} </td>
                                            <td className='text-primary py-4' >{item.pivot.invitation_status} </td>
                                            <td >
                                                <Link
                                                    to='#'
                                                    onClick={() => deleteTeamMember(item.id)}
                                                    className='text-danger mx-5 my-3'
                                                >
                                                    <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <div className='d-flex justify-content-center'>

                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                )

                            ) : slug === 'rejected' ? (
                                isShowRejectedUsers === false ? (
                                    rejectedusers.map((item, index) => (
                                        <tr className='border-bottom border-secondary ' key={index}
                                            style={{ cursor: 'pointer' }}

                                        >
                                            <td className='text-primary py-4'
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >{item.email} </td>
                                            <td className='text-primary py-4' >{item.pivot.invitation_status} </td>
                                            <td >
                                                <Link
                                                    to='#'
                                                    onClick={() => deleteTeamMember(item.id)}
                                                    className='text-danger mx-5 my-3'
                                                >
                                                    <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <div className='d-flex justify-content-center'>

                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                )
                            ) : slug === 'pending' ? (
                                isShowPendingUsers === false ? (
                                    pendingusers.map((item, index) => (
                                        <>
                                            <tr>

                                            </tr>
                                            <tr className='border-bottom border-secondary ' key={index}
                                                style={{ cursor: 'pointer' }}

                                            >
                                                <td className='text-primary py-4'
                                                    onClick={() => {
                                                        navigate(`/${username}/profile/${item.id}`)
                                                    }}
                                                >{item.email} </td>
                                                <td className='text-primary py-4' >{item.pivot.invitation_status} </td>
                                                <td >
                                                    <Link
                                                        to='#'
                                                        onClick={() => deleteTeamMember(item.id)}
                                                        className='text-danger mx-5 my-3'
                                                    >
                                                        <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </>

                                    ))
                                ) : (<div className='d-flex justify-content-center'>

                                    <Spinner animation="border" variant="primary" />
                                </div>)
                            ) : (null)


                        }

                    </tbody>

                </table>
                {alertArray.error_dlg ? (
                    <SweetAlert
                        error
                        title={alertArray.dynamic_title}
                        onConfirm={() => setAlertArray({ error_dlg: false })}
                    >
                        {alertArray.dynamic_description}
                    </SweetAlert>
                ) : null}
                {alertArray.confirm_both === true &&
                    <SweetAlert
                        title='Are you sure?'
                        warning
                        showCancel
                        confirmBtnBsStyle='success'
                        cancelBtnBsStyle='danger'
                        confirmBtnText='YES'
                        cancelBtnText='NO'
                        onConfirm={() =>
                            setAlertArray({
                                confirm_both: false,
                                success_dlg: true,
                                dynamic_title: 'Deleted',
                                dynamic_description: 'Your Record has been deleted.',
                            })
                        }
                        onCancel={() =>
                            setAlertArray({
                                confirm_both: false,
                                error_dlg: true,
                                dynamic_title: 'Cancelled',
                                dynamic_description: 'Your Record is safe :)',
                            })

                        }
                    >
                        You want to delete permanently
                    </SweetAlert>
                }
                {alertArray.success_dlg &&
                    <SweetAlert
                        success
                        title={alertArray.dynamic_title}
                        onConfirm={() => deleteTeam(id)}
                    >   {alertArray.dynamic_description}
                    </SweetAlert>
                }
                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={1000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}
                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}
                {successModel === true ? (
                    <SweetAlert
                        title={successModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        success
                        onConfirm={() => setSuccessModel(false)}

                    ></SweetAlert>
                ) : (null)}
                <Modal
                    size='md'
                    isOpen={inviteModel}
                    centered={true}
                    toggle={() => setInviteModel(!inviteModel)}
                >
                    <ModalHeader
                        toggle={() => setInviteModel(!inviteModel)}
                    >
                        Invite new member in Team
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Input
                                            name='InviteEmail'
                                            type="email"
                                            id='name'
                                            placeholder='Enter email'
                                            value={inviteEmail}
                                            onChange={handleTextes}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <Label>
                                            Select Role
                                        </Label>
                                        <Select
                                            value={selectedRole}
                                            onChange={handleSelectedRole}
                                            options={roleOption}
                                            classNamePrefix='select2-selection'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className='text-end'>
                            <Button
                                color='secondary'
                                onClick={() => setInviteModel(!inviteModel)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='text-end'>
                            <Button
                                type='button'
                                color='primary'
                                onClick={InviteNow}
                            >
                                Invite a new member by email
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal
                    size='md'
                    isOpen={addModel}
                    centered={true}
                    toggle={() => setAddModel(!addModel)}
                >
                    <ModalHeader
                        toggle={() => setAddModel(!addModel)}
                    >
                        Add member in Team
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>
                                            Select mail
                                        </Label>
                                        <Select
                                            placeholder={dropdownTitle}
                                            value={selectedMember}
                                            options={memberOptions}
                                            onChange={handleMembers}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <Label>
                                            Select Role
                                        </Label>
                                        <Select
                                            value={selectedRole}
                                            onChange={handleSelectedRole}
                                            options={roleOption}
                                            classNamePrefix='select2-selection'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className='text-end'>
                            <Button
                                color='secondary'
                                onClick={() => setAddModel(!addModel)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='text-end'>
                            <Button
                                type='button'
                                color='primary'
                                // onClick={() => setAddModel(!addModel)}

                                onClick={addNewMemberBymail}
                            >
                                Add  member by email
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
               
            </div>
        </div>
             
           
    );
}

export default TeamDashboard;