import { baseURL } from '../../../src/components/Admin/BaseURL'
export const getProfile = async (accessToken, userID) => {
    try {
        const response = await fetch(
            `${baseURL}/profile/${userID}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};

export const UpdateProfileRequest = async (accessToken, firstName, lastName, Email, AltEmail, userName) => {
    try {
        const response = await fetch(`${baseURL}/profile`, {
            method: "POST",
           
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                email: Email,
                alt_email: AltEmail,
                username: userName
            }),
        });
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};

export const UpdateProfileImageRequest = async (accessToken, formData) => {
    try {
        const response = await fetch(`${baseURL}/profile-image`, {
            method: "POST",
            headers: {
                // "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
           
            body: formData,
        });
        const result = await response.json();
        return result;
    } catch (e) {
        console.log('catch')


        throw e;
    }
};