import { baseURL } from '../../../src/components/Admin/BaseURL'
export const getGroupsDetail = async (accessToken, userName, boardID) => {
    const response = await fetch(
        `${baseURL}/${userName}/boards/${boardID}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const getGroupsColsRowsRequest = async (accessToken, userName, groupID) => {
    const response = await fetch(
        `${baseURL}/${userName}/board_lists/${groupID}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const getColTypesRequest = async (accessToken, userName) => {
    const response = await fetch(
        `${baseURL}/${userName}/column_types`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const createItemRequest = async (accessToken, userName, itemText, groupID) => {
    const response = await fetch(
        `${baseURL}/${userName}/list_items`,
        {
            body: JSON.stringify({
                title: itemText,
                list: groupID,
                // status:'',
                // parent:''
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const updateItemRequest = async (accessToken, userName, itemText, itemID) => {
    const response = await fetch(
        `${baseURL}/${userName}/list_items/${itemID}`,
        {
            body: JSON.stringify({
                title: itemText
            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const deleteItemRequest = async (accessToken, userName,  itemID) => {
    const response = await fetch(
        `${baseURL}/${userName}/list_items/${itemID}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const createColRequest = async (accessToken, userName, boardID, colHeading, type) => {
    const response = await fetch(
        `${baseURL}/${userName}/board_columns`,
        {
            body: JSON.stringify({
                board: boardID,
                heading: colHeading,
                type: type,
                position: '2'


            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const deleteColRequest = async (accessToken, userName,colID ) => {
    const response = await fetch(
        `${baseURL}/${userName}/board_columns/${colID}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const updateColRequest = async (accessToken, userName, title, colID) => {
    const response = await fetch(
        `${baseURL}/${userName}/board_columns/${colID}`,
        {
            body: JSON.stringify({
                heading: title,
            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const createGroupRequest = async (accessToken, userName, id, title) => {
    console.log(accessToken, userName, id, title)

    try {
        const response = await fetch(
            `${baseURL}/${userName}/board_lists`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify({
                    title: title,
                    board: id,
                })
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const updateGroupRequest = async (accessToken, userName, id, title) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/board_lists/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify({
                    title: title,
                })
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const deleteGroupRequest = async (accessToken, userName, id) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/board_lists/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
}; 
export const updateCellRequest = async (accessToken, userName, id, cellText, cellColor) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/list_cells/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify(
                   { value:[{
                    value: cellText,
                    label: cellText,
                    color: cellColor
                }]}),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const dropdownValueDeleteRequest = async (accessToken, userName, id) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/column_values/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const dropdownValueAddRequest = async (accessToken, userName, id, title, color) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/column_values`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify({
                    column: id,
                    value: title,
                    label: title,
                    color: color,
                }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const dropdownValueUpdateRequest = async (accessToken, userName, id, title, color) => {
    try {
     let body;
        if (title){
      body = JSON.stringify({
             value: title,
             label: title,
         })
     }
        if (color){
        body = JSON.stringify({
             color: color,
         })
     }
        const response = await fetch(
            `${baseURL}/${userName}/column_values/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: body
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const updateCellLinkRequest = async (accessToken, userName, id, cellLink, cellLinkText, cellColor) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/list_cells/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify(
                    {
                        value: [{
                            value: cellLink,
                            label: cellLinkText,
                            color: cellColor
                        }]
                    }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const addPersonRequest = async (
    accessToken,
    userName,
    id,
    userID,
    boardID
) => {
    
    try {
        const response = await fetch(
            `${baseURL}/${userName}/list_cells/add-person/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify({
                    user_id: userID,
                    board: boardID
                }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const deletePersonRequest = async (
    accessToken,
    userName,
    id,
    userID,
    boardID
) => {

    try {
        const response = await fetch(
            `${baseURL}/${userName}/list_cells/remove-person/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify({
                    user_id: userID,
                    board: boardID
                }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const updateRadioBtnRequest = async (accessToken, userName, id, cellValue, ) => {
    try {
        const response = await fetch(
            `${baseURL}/${userName}/list_cells/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify(
                    {
                        value: [cellValue]
                    }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const updateCheckboxesRequest = async (accessToken, userName, id, cellValue,) => {
    try {
      
        const response = await fetch(
            `${baseURL}/${userName}/list_cells/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
                body: JSON.stringify(
                    {
                        value: cellValue
                    }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
