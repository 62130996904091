import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';
import * as action from '../../../redux/action';
import { useDispatch } from "react-redux";
import {
    UpdateWorkspaceTitleRequest,
    UpdateWorkspaceDescriptionRequest,
    deleteWorkspaceRequest,
    getSingleWorkspaceRequest,
    addUser,
    getMembersmailsRequest,
    deleteWorkspaceUser
} from '../../../Services/AdminServices/WorkspaceServices';
import {
    getBoardWorkspaceRoles,
    inviteUser
} from '../../../Services/AdminServices/TeamServices';
import Select from 'react-select';
import {
    Container,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Button,
    Form,
    Row,
    Col
} from 'reactstrap'
const WorkspaceComponent = (props) => {
    const { username, workspaceID } = useParams();
    const [inviteModel, setInviteModel] = useState(false);
    const [roles, setRoles] = useState([]);
    const [deleteModel, setDeleteModel] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inviteEmail, setInviteEmail] = useState('');
    const [mails, setMails] = useState([]);
    const [selectedMember, setSelectedMember] = useState('');
    const [addModel, setAddModel] = useState(false);
    const [dropdownTitle, setDropdownTitle] = useState(' Add member');
    const [successModelText, setSuccessModelText] = useState('');
    const [successModel, setSuccessModel] = useState(false);
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken } = transformedData;
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    const [reloadWorkspaces, setReloadWorkspaces] = useState(false)
    const [workspaceName, setWorkspaceName] = useState('');
    const [workspaceUsers, setWorkspaceUsers] = useState([]);

    
    const [workspaceDescription, setWorkspaceDescription] = useState('');
    const [alertArray, setAlertArray] = useState({

        confirm_both: false,
        success_dlg: false,
        error_dlg: false,
        dynamic_title: '',
        dynamic_description: '',

    });
    const [selectedRole, setSelectedRole] = useState("");
    const [userRole, setUserRole] = useState('')
    const [isDisableField, setIsDisableField] = useState(false);
   
    useEffect(() => {
        getSingleWorkspace()
        getMembersmails()
        getBoardsWorkspacesRoles()
    }, [workspaceID]);
    useEffect(() => {
        if (userRole !== 'viewer') {
            setIsDisableField(false)
        }
        else {
            setIsDisableField(true)
        }

    }, [userRole]);
    const getMembersmails = async () => {
        try {
            const result = await getMembersmailsRequest(username, accessToken, workspaceID);
            if (result.status === true) {

                setMails(result.users);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const addNewMemberBymail = async () => {
        setAddModel(false)
        const result = await addUser(
            selectedMember.value,
            workspaceID,
            selectedRole.value,
            username,
            accessToken
        );
        if (result.status == true) {

            setSelectedMember('');
            setSelectedRole('')
            setDropdownTitle('Add member')
            setSuccessModelText(result.message)
            setSuccessModel(true);
            getMembersmails()
        }
        else {

            setErrorModelText(result.message)
            setErrorModel(true);
        }


    }
    const deleteWorkspace = async () => {
        setAlertArray({
            success_dlg: false
        })
        try {
            const result = await deleteWorkspaceRequest(accessToken, username, workspaceID);
            if (result.status === true) {
                navigate(`/${username}/workspaces`);
                setReloadWorkspaces(!reloadWorkspaces)
                dispatch(action.reloadWorkspaces(reloadWorkspaces));
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const handleWorkspaceName = async (event) => {
        await setWorkspaceName(event.target.value);
    }
    const handleWorkspaceDescription = async (event) => {
        await setWorkspaceDescription(event.target.value);
    }
    const UpdateWorkspaceTitle = async () => {
        try {
            const result = await UpdateWorkspaceTitleRequest(accessToken, username, workspaceID, workspaceName);
            if (result.status === true) {
                dispatch(action.updateWorkspaceLocally(workspaceID, workspaceName, 'workspaceName'));
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const UpdateWorkspaceDescription = async () => {
        try {
            const result = await UpdateWorkspaceDescriptionRequest(accessToken, username, workspaceID, workspaceDescription);
            if (result.status === true) {
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const onMouseOver = (e) => {
        if (userRole !== 'viewer') {
            return e.target.style.backgroundColor = '#DCDCDC'
        }

    }
    const onMouseOut = (e) => {
        if (userRole !== 'viewer') {
            return e.target.style.backgroundColor = ''
        }
    }
    const getSingleWorkspace = async () => {
        try {
            const result = await getSingleWorkspaceRequest(accessToken, username, workspaceID);

            if (result.status === 200) {
                setWorkspaceUsers(result.data.workspace.users)
                setWorkspaceName(result.data.workspace.title);
                setWorkspaceDescription(result.data.workspace.description);
                setUserRole(result.data.role)
            }
        } catch (err) {
            console.log('workspace',err)
            // setErrorModelText(err.response.data.message)
            // setErrorModel(true);
        }
    }
    const memberOptions = mails.map((item) => ({
        label: item.email, value: item.id,

    }));
    const handleMembers = (e) => {
        setSelectedMember(e)
    }
    const handleSelectedRole = async (role) => {

        await setSelectedRole(role)

    }
    const roleOption = roles.map((item) => ({
        label: item.name, value: item.id
    }));
    const getBoardsWorkspacesRoles = async () => {
        try {
            const result = await getBoardWorkspaceRoles();
            if (result.status === 200) {

                setRoles(result.data);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const InviteNow = async () => {
        setInviteModel(!inviteModel)
        if (
            typeof inviteEmail !== 'undefined' &&
            inviteEmail !== '' &&
            typeof selectedRole.value !== 'undefined' &&
            selectedRole.value !== ''

        ) {
            const result = await inviteUser(
                inviteEmail,
                selectedRole.value,
                workspaceID,
                'workspace',
                username,
                accessToken
            );
            if (result.status == true) {
                setInviteEmail('');
                setSuccessModelText(result.message)
                setSuccessModel(true);
            }
            else {

                setErrorModelText(result.message)
                setErrorModel(true);
            }

        } else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    const handleTextes = (event) => {
        switch (event.target.name) {
            case 'InviteEmail':
                setInviteEmail(event.target.value)
                break;
            


        }
    }
    const workspaceMemberOptions = workspaceUsers.map((item) => ({
        label: item.email, value: item.id,

    }));
    const deleteWorkspaceMember = async () => {
        setDeleteModel(false)
        const result = await deleteWorkspaceUser(
            selectedMember.value,
            workspaceID,
            username,
            accessToken
        );
        if (result.status == true) {
            const filter = workspaceUsers.filter((item) => {
                return item.id !== selectedMember.value
            })
            setWorkspaceUsers(filter)

            setSelectedMember('');
            setDropdownTitle('Add member')
            setSuccessModelText(result.message)
            setSuccessModel(true);
        }
        else {

            setErrorModelText(result.message)
            setErrorModel(true);
        }


    }
    
    return (
        <React.Fragment>
            <Container fluid >
                <div className="col-9 offset-3 mt-2 ">
                    <div className='d-flex justify-content-between'>
                        <div >
                            <Input
                                style={{ color: 'blue', border: 'white', fontSize: 20, width: 300 }}
                                value={workspaceName}
                                onChange={(e) => handleWorkspaceName(e)}
                                type="text"
                                onBlur={() => UpdateWorkspaceTitle()}
                                onMouseOver={(e) => onMouseOver(e)}
                                onMouseOut={(e) => onMouseOut(e)}
                                disabled={isDisableField}
                            />
                        </div>
                        {userRole !== 'viewer' &&
                            <>
                                <div>
                                    <Button
                                        type='submit'
                                        color='white'
                                        onClick={() => setInviteModel(true)}
                                        className='text-primary'
                                    >
                                        <span className='px-2'>
                                            <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                                                <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                                </path>
                                            </svg>
                                        </span>
                                        Invite member
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type='submit'
                                        color='white'
                                        onClick={() => setAddModel(true)}
                                        className='text-primary'
                                    >
                                        <span className='px-2'>
                                            <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                                                <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                                </path>
                                            </svg>
                                        </span>
                                        Add member
                                    </Button>
                                </div>
                            <div>
                                <Button
                                    type='submit'
                                    color='white'
                                    onClick={() => setDeleteModel(true)}
                                    className='text-primary'
                                >
                                   
                                    Delete member
                                </Button>
                            </div>
                                <div>
                                    <Link
                                        to='#'
                                        onClick={() => setAlertArray({ confirm_both: true, })}
                                        className='text-danger mx-5 my-3'
                                    >
                                        <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                    </Link>
                                </div>

                            </>
                        }


                    </div>
                    <div>
                        <Input
                            style={{ color: 'blue', border: 'white', fontSize: 20, width: 500 }}
                            onMouseOver={(e) => onMouseOver(e)}
                            onMouseOut={(e) => onMouseOut(e)}
                            placeholder='Add Workspace description'
                            value={workspaceDescription}
                            onChange={(e) => handleWorkspaceDescription(e)}
                            type="text"
                            onBlur={() => UpdateWorkspaceDescription()}
                            disabled={isDisableField}
                        />
                    </div>

                    {alertArray.error_dlg ? (
                        <SweetAlert
                            error
                            title={alertArray.dynamic_title}
                            onConfirm={() => setAlertArray({ error_dlg: false })}
                        >
                            {alertArray.dynamic_description}
                        </SweetAlert>
                    ) : null}
                    {alertArray.confirm_both === true &&
                        <SweetAlert
                            title='Are you sure?'
                            warning
                            showCancel
                            confirmBtnBsStyle='success'
                            cancelBtnBsStyle='danger'
                            confirmBtnText='YES'
                            cancelBtnText='NO'
                            onConfirm={() =>
                                setAlertArray({
                                    confirm_both: false,
                                    success_dlg: true,
                                    dynamic_title: 'Deleted',
                                    dynamic_description: 'Your Record has been deleted.',
                                })
                            }
                            onCancel={() =>
                                setAlertArray({
                                    confirm_both: false,
                                    error_dlg: true,
                                    dynamic_title: 'Cancelled',
                                    dynamic_description: 'Your Record is safe :)',
                                })

                            }
                        >
                            You want to delete permanently
                        </SweetAlert>
                    }
                    {alertArray.success_dlg &&
                        <SweetAlert
                            success
                            title={alertArray.dynamic_title}
                            onConfirm={() => deleteWorkspace()}
                        >   {alertArray.dynamic_description}
                        </SweetAlert>
                    }
                    {errorModel === true ? (
                        <SweetAlert
                            title={errorModelText}
                            timeout={1000}
                            style={{
                                position: "absolute",
                                top: "0px",
                                // right: "0px"
                            }}
                            showCloseButton={false}
                            showConfirm={false}
                            error
                            onConfirm={() => setErrorModel(false)}

                        ></SweetAlert>
                    ) : (null)}
                    <Modal
                        size='md'
                        isOpen={addModel}
                        centered={true}
                        toggle={() => setAddModel(!addModel)}
                    >
                        <ModalHeader
                            toggle={() => setAddModel(!addModel)}
                        >
                            Add member in workspace
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>
                                                Select mail
                                            </Label>
                                            <Select
                                                placeholder={dropdownTitle}
                                                value={selectedMember}
                                                options={memberOptions}
                                                onChange={handleMembers}
                                            />
                                        </div>
                                        <div className='mb-3'>
                                            <Label>
                                                Select Role
                                            </Label>
                                            <Select
                                                value={selectedRole}
                                                onChange={handleSelectedRole}
                                                options={roleOption}
                                                classNamePrefix='select2-selection'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <div className='text-end'>
                                <Button
                                    color='secondary'
                                    onClick={() => setAddModel(!addModel)}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='text-end'>
                                <Button
                                    type='button'
                                    color='primary'
                                    // onClick={() => setAddModel(!addModel)}

                                    onClick={addNewMemberBymail}
                                >
                                    Add  member by email
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        size='md'
                        isOpen={inviteModel}
                        centered={true}
                        toggle={() => setInviteModel(!inviteModel)}
                    >
                        <ModalHeader
                            toggle={() => setInviteModel(!inviteModel)}
                        >
                            Invite new member in workspace
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Input
                                                name='InviteEmail'
                                                type="email"
                                                id='name'
                                                placeholder='Enter email'
                                                value={inviteEmail}
                                                onChange={handleTextes}
                                            />
                                        </div>
                                        <div>
                                            <Label>
                                                Select Role
                                            </Label>
                                            <Select
                                                value={selectedRole}
                                                onChange={handleSelectedRole}
                                                options={roleOption}
                                                classNamePrefix='select2-selection'


                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <div className='text-end'>
                                <Button
                                    color='secondary'
                                    onClick={() => setInviteModel(!inviteModel)}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='text-end'>
                                <Button
                                    type='button'
                                    color='primary'
                                    onClick={InviteNow}
                                >
                                    Invite a new member by email
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                    {successModel === true ? (
                        <SweetAlert
                            title={successModelText}
                            timeout={2000}
                            style={{
                                position: "absolute",
                                top: "0px",
                                // right: "0px"
                            }}
                            showCloseButton={false}
                            showConfirm={false}
                            success
                            onConfirm={() => setSuccessModel(false)}

                        ></SweetAlert>
                    ) : (null)}

                    <Modal
                        size='md'
                        isOpen={deleteModel}
                        centered={true}
                        toggle={() => setDeleteModel(!deleteModel)}
                    >
                        <ModalHeader
                            toggle={() => setDeleteModel(!deleteModel)}
                        >
                            Delete member in Board
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>
                                                Select mail
                                            </Label>
                                            <Select
                                                placeholder={dropdownTitle}
                                                value={selectedMember}
                                                options={workspaceMemberOptions}
                                                onChange={handleMembers}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <div className='text-end'>
                                <Button
                                    color='secondary'
                                    onClick={() => setDeleteModel(!deleteModel)}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='text-end'>
                                <Button
                                    type='button'
                                    color='primary'
                                    // onClick={() => setDeleteModel(!deleteModel)}

                                    onClick={deleteWorkspaceMember}
                                >
                                    Delete  member
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </div>

            </Container>

        </React.Fragment>
    );
}

export default WorkspaceComponent;