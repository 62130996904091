import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link,  useParams, useNavigate } from 'react-router-dom';
import * as action from '../../../redux/action';
import {  useDispatch, useSelector } from "react-redux";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import Avatar from 'react-avatar';
import { CirclePicker } from 'react-color'
import {
    inviteUser,
    getBoardWorkspaceRoles,

} from '../../../Services/AdminServices/TeamServices';
import {
    UpdateBoardRequest,
    deleteBoardRequest,
    getMembersmailsRequest,
    addUser,
    deleteBoardUser
} from '../../../Services/AdminServices/BoardServices'

import {
    getGroupsDetail,
    getGroupsColsRowsRequest,
    createItemRequest,
    createColRequest,
    getColTypesRequest,
    updateItemRequest,
    deleteItemRequest,
    deleteColRequest,
    updateColRequest,
    createGroupRequest,
    deleteGroupRequest,
    updateGroupRequest,
    updateCellRequest,
    dropdownValueDeleteRequest,
    dropdownValueAddRequest,
    dropdownValueUpdateRequest,
    updateCellLinkRequest,
    addPersonRequest,
    updateRadioBtnRequest,
    deletePersonRequest,
    updateCheckboxesRequest
} from '../../../Services/AdminServices/TableServices'
import {
    Input,
    Col,
    Row,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
    Button,
    UncontrolledTooltip,
    Spinner
} from 'reactstrap'
const BoardComponents = (props) => {
    const boardReloadID = useSelector(state => state.reducer.boardID);
    const navigate = useNavigate();
    const { username, boardID } = useParams();
    const dispatch = useDispatch();
    const [addModel, setAddModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedMember, setSelectedMember] = useState('');
    const [mails, setMails] = useState([]);
    const [boardDelModel, setBoardDelModel] = useState(false);
    const [inviteModel, setInviteModel] = useState(false);
    const [onclickIndex, setOnclickIndex] = useState('');
    const [dropdownCol, setDropdownCol] = useState(false);
    const [statusModel, setStatusModel] = useState(false);
    const [errorModel, setErrorModel] = useState(false);
    const [alertArray, setAlertArray] = useState({

        confirm_both: false,
        success_dlg: false,
        error_dlg: false,
        dynamic_title: '',
        dynamic_description: '',

    });
    const [statusIndex, setStatusIndex] = useState({
        groupIndex: '',
        boardIndex: '',
        rowIndex: '',
        colIndex: ''
    });
    const [dropdownTitle, setDropdownTitle] = useState(' Add member')
    const [groupName, setGroupName] = useState('');
    const [itemText, setItemText] = useState('');
    const [date, setDate] = useState(new Date());
    const [inviteEmail, setInviteEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState("");
    const [selectedBoardName, setSelectedBoardName] = useState('');
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, userName } = transformedData;
    const [successModelText, setSuccessModelText] = useState('');
    const [successModel, setSuccessModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState("Please fill required field");
    const [roles, setRoles] = useState([]);
    const [reloadBoard, setReloadBoard] = useState(false);
    const [groupsList, setGroupList] = useState([]);
    const [colTypes, setColTypes] = useState([]);
    const [updatedGroupName, setUpdatedGroupName] = useState('');
    const [updatedItemText, setUpdatedItemText] = useState('');
    const [updatedColText, setUpdatedColText] = useState('');
    const [board, setBoard] = useState([]);
    const [cellText, setCellText] = useState('');
    const [cellDate, setCellDate] = useState('');
    const [isTableLoader, setIsTableLoader] = useState(false);
    const [isGroupLoader, setIsGroupLoader] = useState(false);
    const [isRowLoader, setIsRowLoader] = useState(false);
    const [isColLoader, setIsColLoader] = useState(false);
    const [isShowGroupField, setIsShowGroupField] = useState(false);
    const [pirorities, setPirorities] = useState([]);
    const [colValues, setColValues] = useState([]);
    const [isEditLabels, setIsEditLabels] = useState(false);
    const [dropdownText, setDropdownText] = useState('');
    const [colorModel, setColorModel] = useState(false);
    const [cellLink, setCellLink] = useState('');
    const [cellLinkText, setCellLinkText] = useState('');
    const [dropdownValueID, setDropdownValueID] = useState({
        groupID: '',
        boardID: '',
        cellID: '',
        rowID: '',
        cellValueID: ''
    })
    const [groupIndex, setGroupIndex] = useState({
        mainIndex: '',
        rowIndex: '',
        colIndex: '',
        groupID: '',
        rowID: ''
    });
    const [cellValueColor, setCellValueColor] = useState('#673ab7');
    const [boardUsers, setBoardUsers] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [isDisableField, setIsDisableField] = useState(false);
    const memberOptions = mails.map((item) => ({
        label: item.email, value: item.id,

    }));
    const boardMemberOptions = boardUsers.map((item) => ({
        label: item.email, value: item.id,

    }));

    useEffect(() => {
        getBoardsWorkspacesRoles();
        getColTypes();
        // getPirorities();
    }, []);
    useEffect(() => {
        setBoard([]);
        getSelectedBoardGroupsList(boardID);
        getMembersmails()
    }, [boardID]);

    useEffect(() => {
        if (userRole !== 'viewer') {
            setIsDisableField(false)
        }
        else {
            setIsDisableField(true)
        }
    }, [userRole]);

    const validateIsUseValue = (colValue, colValueID, colID)=>{
        let lists = [...board];
        let isValueMatched = false;

        lists.map((list, listIndex) => {
            list.board_list.list_items.map((item, itemIndex) => {
                item.list_cells.map((cell, cellIndex) => {
                    if(cell.column === colID){
                        if (colValue === cell.value[0].label || colValue === cell.value[0].value) {

                            isValueMatched = true
                        }
                    }
                       
                    
                       
                })
            })
        })
        if (isValueMatched){
            setErrorModelText('You can not delete a lable while in use')
            setErrorModel(true);
        }
        else {
            dropdownValueDelete(colValueID, colID)
        }

    }
    const getMembersmails = async () => {
        try {
            const result = await getMembersmailsRequest(username, accessToken, boardID);
            if (result.status === true) {

                setMails(result.users);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const handleMembers = (e) => {
        setSelectedMember(e)
    }
    
    const deleteBoardMember = async () => {
        setDeleteModel(false)
        const result = await deleteBoardUser(
            selectedMember.value,
            boardID,
            username,
            accessToken
        );
        if (result.status == true) {
            const filter = boardUsers.filter((item) => {
                return item.id !== selectedMember.value
            })
            setBoardUsers(filter)
          
            setSelectedMember('');
            setDropdownTitle('Add member')
            setSuccessModelText(result.message)
            setSuccessModel(true);
        }
        else {

            setErrorModelText(result.message)
            setErrorModel(true);
        }


    }

    const addNewMemberBymail = async () => {
        setAddModel(false)
        const result = await addUser(
            selectedMember.value,
            boardID,
            selectedRole.value,
            username,
            accessToken
        );
        if (result.status == true) {

            setSelectedMember('');
            setSelectedRole('')
            setDropdownTitle('Add member')
            setSuccessModelText(result.message)
            setSuccessModel(true);
            getMembersmails()
        }
        else {

            setErrorModelText(result.message)
            setErrorModel(true);
        }


    }
    const getBoardsWorkspacesRoles = async () => {
        try {
            const result = await getBoardWorkspaceRoles();
            if (result.status === 200) {

                setRoles(result.data);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const dropdownValueIDRecord = (groupID, boardID, cellID, rowID, cellValueID) => {
        setDropdownValueID({
            groupID: groupID,
            boardID: boardID,
            cellID: cellID,
            rowID: rowID,
            cellValueID: cellValueID
        })
        setStatusModel(true);
    }
    const confirmBoardDelet = () => {
        setAlertArray({
            confirm_both: true,
        });
        setBoardDelModel(true);

    }
    const confirmGroupDelet = async (groupIndex, groupID) => {
        await setAlertArray({
            confirm_both: true,
        })
        await setGroupIndex({
            mainIndex: groupIndex,
            rowIndex: '',
            colIndex: '',
            groupID: groupID
        })
        await setGroupItemRecord({
            groupDel: true,
            rowDel: false,
            colDel: false
        })
    }
    const setStatusRecord = (groupIndex, boardIndex, rowIndex, colIndex) => {
        setStatusIndex({
            groupIndex: groupIndex,
            boardIndex: boardIndex,
            rowIndex: rowIndex,
            colIndex: colIndex
        })
        setStatusModel(true);
    }
    const confirmColDelete = async (headingID) => {
        await setAlertArray({
            confirm_both: true,
        })
        await setGroupIndex({
            mainIndex: '',
            rowIndex: '',
            colIndex: headingID
        })
        await setGroupItemRecord({
            groupDel: false,
            rowDel: false,
            colDel: true
        })
    }
    const confirmRowDelete = async (boardIndex, rowIndex, rowId) => {
        await setAlertArray({
            confirm_both: true,
        })
        await setGroupIndex({
            mainIndex: boardIndex,
            rowIndex: rowIndex,
            colIndex: '',
            rowID: rowId

        })
        await setGroupItemRecord({
            groupDel: false,
            rowDel: true,
            colDel: false,
        })
    }
    const [groupItemRecord, setGroupItemRecord] = useState({
        groupDel: false,
        rowDel: false,
        colDel: false
    })
    ////////////////
    const onChangeDate = async (e) => {
        setDate(e);
        setCellDate(e.toDateString())

    }
    const setStatus = (val, color) => {
        if (userRole !== 'viewer') {
            let list = [...board]
            list[statusIndex.boardIndex].board_list.list_items[statusIndex.rowIndex].list_cells[statusIndex.colIndex].value[0].value = val;
            list[statusIndex.boardIndex].board_list.list_items[statusIndex.rowIndex].list_cells[statusIndex.colIndex].value[0].color = color
            setBoard(list);
            setStatusModel(false);
        }



    }
    const deleteLocallyGroup = (id) => {
        if (userRole !== 'viewer') {
            let list = [...groupsList];
            list.splice(id, 1);
            setGroupList(list)
        }
    }
    const updateLocallyGroupName = async (event, id) => {
        if (userRole !== 'viewer') {
            let list = [...groupsList];
            list[id].title = event.target.value;
            await setGroupList(list);
            await setUpdatedGroupName(event.target.value);
        }
    }
    const deleteLocallyItem = (boardIndex, rowIndex) => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list[boardIndex].board_list.list_items.splice(rowIndex, 1);
            setBoard(list)
        }
    }
    const updateLocallyItem = async (event, mainIndex, rowIndex) => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list[mainIndex].board_list.list_items[rowIndex].title = event.target.value;
            await setBoard(list);
            await setUpdatedItemText(event.target.value);
        }
    }
    const deleteLocallyCol = (id) => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list.map((group, groupIndex) => {
                group.columns.map((column, colIndex) => {
                    if (column.id === id) {
                        list[groupIndex].columns.splice(colIndex, 1)
                    }
                })
            })
            list.map((group, groupIndex) => {
                group.board_list.list_items.map((row, rowIndex) => {
                    row.list_cells.map((cell, cellID) => {
                        if (cell.column === id) {
                            list[groupIndex].board_list.list_items[rowIndex].list_cells.splice(cellID, 1)
                        }
                    })

                })
            })
            setBoard(list);
        }

    }
    const updateLocallyCol = async (boardIndex, headingIndex, event, id) => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list.map((group, groupIndex) => {
                group.columns.map((column, colIndex) => {
                    if (column.id === id) {
                        list[groupIndex].columns[colIndex].heading = event.target.value;
                    }
                })
            })
            await setBoard(list);
            await setUpdatedColText(event.target.value);
        }
    }
    const updateLocallyCellText = async (value) => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list[statusIndex.boardIndex].board_list.list_items[statusIndex.rowIndex].list_cells[statusIndex.colIndex].value[0].value = value;
            // setCellText(event.target.value)
            setBoard(list);
        }
    }
    const updateLocallyRadioBtn = async (value, cellID) => {
        if (userRole !== 'viewer') {
            let lists = [...board];
            lists.map((list, listIndex) => {
                list.board_list.list_items.map((item, itemIndex) => {
                    item.list_cells.map((cell, cellIndex) => {
                        if (cell.id === cellID)
                            lists[listIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value[0] = value;
                    })
                })
            })
            setBoard(lists);
        }
    }
    const updateLocallyLink = async () => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list[statusIndex.boardIndex].board_list.list_items[statusIndex.rowIndex].list_cells[statusIndex.colIndex].value[0].value = cellLink;
            list[statusIndex.boardIndex].board_list.list_items[statusIndex.rowIndex].list_cells[statusIndex.colIndex].value[0].label = cellLinkText;

            setBoard(list);
            setCellLink('');
            setCellLinkText('');
        }
    }
    const updateLocallyCellDate = async () => {
        if (userRole !== 'viewer') {
            let list = [...board];
            list[statusIndex.boardIndex].board_list.list_items[statusIndex.rowIndex].list_cells[statusIndex.colIndex].value[0].value = cellDate;
            setBoard(list);
        }
    }
    const onKeyPressAddGroup = (e) => {
        if (e.which === 13) {
            createGroup()
        }
    }
    const onKeyPressAddRow = (e, itemID) => {
        if (e.which === 13) {
            createItem(itemID)
        }
    }
    const updateLocallyDropdownValue = (e, colID, valueID) => {

        let lists = [...board]
        let cIndex;
        let lIndex;
        let vIndex;

        lists.map((list, listIndex) => {
            list.columns.map((column, columnIndex) => {
                if (column.id === colID) {
                    column.column_values.map((value, valueIndex) => {
                        if (value.id === valueID) {
                            cIndex = columnIndex
                            lIndex = listIndex
                            vIndex = valueIndex
                        }
                    })

                }
            })
        })
        lists[lIndex].columns[cIndex].column_values[vIndex].value = e.target.value
        lists[lIndex].columns[cIndex].column_values[vIndex].label = e.target.value

        lists.map((list, listIndex) => {
            list.board_list.list_items.map((item, itemIndex) => {
                item.list_cells.map((column, columnIndex) => {
                    column.board_column.column_values.map((colValue, colValueIndex) => {
                        if (colValue.id === valueID) {
                            lists[listIndex].board_list.list_items[itemIndex].list_cells[columnIndex].board_column.column_values[colValueIndex].value = e.target.value;
                            lists[listIndex].board_list.list_items[itemIndex].list_cells[columnIndex].board_column.column_values[colValueIndex].label = e.target.value;

                            // column.board_column.column_values.value = e.target.value;
                            // column.board_column.column_values.label = e.target.value
                        }
                    })
                })
            })
        })

        setBoard(lists);


    }
    const updateLocallyDropdownValueColor = (e, colID, valueID) => {
           
        let lists = [...board]
        let cIndex;
        let lIndex;
        let vIndex;
        lists.map((list, listIndex) => {
            list.columns.map((column, columnIndex) => {
                if (column.id === colID) {
                    column.column_values.map((value, valueIndex) => {
                        if (value.id === valueID) {
                            cIndex = columnIndex
                            lIndex = listIndex
                            vIndex = valueIndex
                        }
                    })

                }
            })
        })

        lists[lIndex].columns[cIndex].column_values[vIndex].color = e

        setBoard(lists);

    }
    ////////////////
    const handleText = (event) => {
        if (userRole !== 'viewer') {
            switch (event.target.id) {
                case 'groupTitle':
                    setGroupName(event.target.value);
                    break;
                case 'itemText':
                    setItemText(event.target.value);
                    break;
            }
        }

    }
    const handleInvitationText = (event) => {
        if (userRole !== 'viewer') {
            switch (event.target.name) {
                case 'InviteEmail':
                    setInviteEmail(event.target.value)
                    break;

            }
        }
    }
    const InviteNow = async () => {
        setInviteModel(!inviteModel)
        if (
            typeof inviteEmail !== 'undefined' &&
            inviteEmail !== '' &&
            typeof selectedRole.value !== 'undefined' &&
            selectedRole.value !== '' &&
            typeof boardID !== 'undefined' &&
            boardID !== ''
        ) {
            const result = await inviteUser(
                inviteEmail,
                selectedRole.value,
                boardID,
                'board',
                username,
                accessToken
            );
            if (result.status == true) {
                setInviteEmail('');
                setSuccessModelText(result.message)
                setSuccessModel(true);
            }
            else {

                setErrorModelText(result.message)
                setErrorModel(true);
            }

        } else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    const handleSelectedRole = async (role) => {

        await setSelectedRole(role)

    }
    const roleOption = roles.map((item) => ({
        label: item.name, value: item.id
    }));
    const handleBoardtext = (event) => {
        setSelectedBoardName(event.target.value)
    }
    const UpdateBoard = async () => {
        if (userRole !== 'viewer') {
            try {
                const result = await UpdateBoardRequest(accessToken, username, boardID, selectedBoardName);
                if (result.status === true) {
                    // console.log(result.message);
                    setReloadBoard(!reloadBoard)
                    dispatch(action.updateBoardLocally(boardID, selectedBoardName));
                    console.log('gffffff', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const deleteBoard = async () => {
        setAlertArray({
            success_dlg: false
        })
        try {
            const result = await deleteBoardRequest(accessToken, username, boardID);
            if (result.status === true) {
                   
                setReloadBoard(!reloadBoard)
                dispatch(action.updateBoardLocally(boardID, 'deleteBoard'));
                navigate(`/${userName}/boards/${boardReloadID}`)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getSelectedBoardGroupsList = async (boardID) => {
        try {
            const result = await getGroupsDetail(accessToken, username, boardID);
            if (result.status === true) {
                setSelectedBoardName(result.board.title)
                console.log('board Role', result.role)
                setUserRole(result.role)
                setBoardUsers(result.board.users)
                result.board.board_lists.map((item, index) => (
                    getGroupsColsRows(item.id)
                ))
                setGroupList(result.board.board_lists)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getGroupsColsRows = async (groupsID) => {
        try {
            setIsTableLoader(true)
            const result = await getGroupsColsRowsRequest(accessToken, username, groupsID);
            if (result.status === true) {
                setBoard(oldArray => [...oldArray, result]);
                setIsTableLoader(false)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getColTypes = async () => {
        try {
            const result = await getColTypesRequest(accessToken, username);
            if (result.status === true) {
                // console.log('getColTypes', result.data)
                setColTypes(result.data);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const createItem = async (groupsID) => {
        try {
            setIsRowLoader(true);
            const result = await createItemRequest(accessToken, username, itemText, groupsID);
            if (result.status === true) {
                setItemText("");
                let list = [...board];
                list.map((group, groupIndex) => {
                    if (group.board_list.id === result.data.list) {
                        list[groupIndex].board_list.list_items.push(result.data)
                    }
                })
                setBoard(list);
                setIsRowLoader(false);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const deleteItem = async () => {

        try {
            setAlertArray({
                success_dlg: false
            })
            const result = await deleteItemRequest(accessToken, username, groupIndex.rowID);
            if (result.status === true) {
                deleteLocallyItem(groupIndex.mainIndex, groupIndex.rowIndex)
                console.log('deleteItem', result)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const updateItem = async (itemID) => {
        if (userRole !== 'viewer') {
            try {
                const result = await updateItemRequest(accessToken, username, updatedItemText, itemID);
                if (result.status === true) {
                    // setItemText('')
                    console.log('updateItem', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const createCol = async (heading, type) => {
        try {
            setIsColLoader(true);
            const result = await createColRequest(accessToken, username, boardID, heading, type);
            if (result.status === true) {
                let list = [...board];
                result.data.list_cells.map((listCells, listCellIndex) => {
                    list.map((group, groupIndex) => {
                        group.board_list.list_items.map((row, rowIndex) => {
                            if (row.id === listCells.item) {
                                list[groupIndex].board_list.list_items[rowIndex].list_cells.push(listCells)
                            }
                        })
                    })
                })

                list.map((group, groupIndex) => {
                    list[groupIndex].columns.push(result.data);

                })
                setBoard(list);
                setIsColLoader(false);

            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const deleteColm = async () => {
        try {
            setAlertArray({
                success_dlg: false
            })
            const result = await deleteColRequest(accessToken, username, groupIndex.colIndex);
            if (result.status === true) {
                deleteLocallyCol(groupIndex.colIndex)
                console.log('createCol', result)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const updateCol = async (colID) => {
        if (userRole !== 'viewer') {
            try {
                const result = await updateColRequest(accessToken, username, updatedColText, colID);
                if (result.status === true) {
                    console.log('createCol', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const createGroup = async () => {
        try {
            setIsGroupLoader(true);
            const result = await createGroupRequest(accessToken, username, boardID, groupName);
            if (result.status === true) {
                setGroupName('');

                setIsShowGroupField(false)
                let listGroups = [...groupsList];
                let listBoard = [...board];
                listGroups.push(result.board_list);
                setGroupList(listGroups);
                listBoard.push(result);
                setBoard(listBoard);
                setIsGroupLoader(false);
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const deleteGroup = async () => {
        try {
            setAlertArray({
                success_dlg: false
            })
            const result = await deleteGroupRequest(accessToken, username, groupIndex.groupID);
            if (result.status === true) {

                deleteLocallyGroup(groupIndex.mainIndex)
                console.log('deleteGroups', result)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const updateGroup = async (groupID) => {
        if (userRole !== 'viewer') {
            try {
                const result = await updateGroupRequest(accessToken, username, groupID, updatedGroupName);
                if (result.status === true) {
                    console.log('createCol', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const updateStatus = async (val, color, cellID) => {
        if (userRole !== 'viewer') {
            setStatusModel(false);
            try {
                const result = await updateCellRequest(accessToken, username, cellID, val, color);
                if (result.status === true) {

                    setStatus(val, color,)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const updateCellText = async (cellID, cellValue) => {
        if (userRole !== 'viewer') {
            if (cellValue !== cellText) {
                try {
                    const result = await updateCellRequest(accessToken, username, cellID, cellValue);
                    if (result.status === true) {
                        setCellText('');
                        console.log('updateCellText', result)
                    }
                } catch (err) {
                    setErrorModelText(err.response.data.message)
                    setErrorModel(true);
                }
            }
        }
    }
    const updateCellLink = async (cellID) => {
        if (userRole !== 'viewer') {
            setStatusModel(false)
            if (cellLink !== '' &&
                cellLink !== 'undefined' &&
                cellLinkText !== '' &&
                cellLinkText !== 'undefined'

            ) {
                try {
                    const result = await updateCellLinkRequest(accessToken, username, cellID, cellLink, cellLinkText);
                    if (result.status === true) {
                        updateLocallyLink();
                        console.log('updateCellText', result)
                    }
                } catch (err) {
                    setErrorModelText(err.response.data.message)
                    setErrorModel(true);
                }
            }
            else {
                setErrorModelText('Please fill required field')
                setErrorModel(true);
            }
        }
    }
    const updateCellDate = async (cellID) => {
        if (userRole !== 'viewer') {
            setStatusModel(false);
            try {
                const result = await updateCellRequest(accessToken, username, cellID, cellDate);
                if (result.status === true) {
                    updateLocallyCellDate();
                    console.log('updateCellDate', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const getColumnValue = (col, groupID) => {
        let lists = [...board]

        lists.map((list, listIndex) => {
            list.columns.map((column, columnIndex) => {
                if (column.id === col) {

                    setColValues(lists[listIndex].columns[columnIndex].column_values);

                }
            })
        })

    }
    const dropdownValueDelete = async (id, colID) => {
        let lists = [...board]
        let cIndex;
        let lIndex;
        let vIndex;

        // console.log(' list.columns', list)
        try {
            const result = await dropdownValueDeleteRequest(accessToken, username, id);
            if (result.status === true) {
                lists.map((list, listIndex) => {
                    list.board_list.list_items.map((item, itemIndex) => {
                        item.list_cells.map((column, columnIndex) => {
                            column.board_column.column_values.map((colValue, colValueIndex) => {
                                if (colValue.id === id) {
                                    column.board_column.column_values.splice(colValueIndex, 1)
                                }
                            })
                        })
                    })
                })
                lists.map((list, listIndex) => {
                    list.columns.map((column, columnIndex) => {
                        if (column.id === colID) {
                            column.column_values.map((value, valueIndex) => {
                                if (value.id === id) {
                                    cIndex = columnIndex
                                    lIndex = listIndex
                                    vIndex = valueIndex
                                }
                            })

                        }
                    })
                })
                lists[lIndex].columns[cIndex].column_values.splice(vIndex, 1)
                setBoard(lists);
            }
            else {
                setErrorModelText(result.message)
                setErrorModel(true);
            }

        } catch (err) {
            console.log("dropdownValueDelete", err)
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const dropdownValueAdd = async (colID, valueID, title,) => {
        let lists = [...board]
        let cIndex;
        let lIndex;
        let vIndex;
        try {
            const result = await dropdownValueAddRequest(accessToken, username, colID, title, cellValueColor);
            if (result.status === true) {
                // board.board_list.list_items[].list_cells[].board_column.column_values
                lists.map((list, listIndex) => {
                    list.board_list.list_items.map((item, itemIndex) => {
                        item.list_cells.map((column, columnIndex) => {
                            if (column.column === colID) {
                                column.board_column.column_values.push(result.column_value)
                            }
                        })
                    })
                })
                lists.map((list, listIndex) => {
                    list.columns.map((column, columnIndex) => {
                        if (column.id === colID) {
                            column.column_values.map((value, valueIndex) => {
                                if (value.id === valueID) {
                                    cIndex = columnIndex
                                    lIndex = listIndex
                                    vIndex = valueIndex
                                }
                            })

                        }
                    })
                })
                lists[lIndex].columns[cIndex].column_values.push(result.column_value)
                setBoard(lists);
                console.log('dropdownValueAdd', result)
            }
        } catch (err) {
            console.log("dropdownValueAdd", err)
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const dropdownValueUpdate = async (colValueID, title, color) => {
        if (title !== dropdownText) {

            try {
                const result = await dropdownValueUpdateRequest(accessToken, username, colValueID, title, color);
                if (result.status === true) {

                    console.log('dropdownValueUpdate', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const addPerson = async (cellID, personsID, boardID) => {
        if (userRole !== 'viewer') {
            try {
                const result = await addPersonRequest(accessToken, username, cellID, personsID, boardID);

                if (result.status === true) {
                    let lists = [...board]
                    lists.map((list, listIndex) => {
                        list.board_list.list_items.map((item, itemIndex) => {
                            item.list_cells.map((cell, cellIndex) => {
                                if (cell.id === cellID) {
                                    lists[listIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value = result.persons
                                }
                            })
                        })
                    })
                    setBoard(lists)
                    console.log('dropdownValueUpdate', result)
                }
                else {
                    setErrorModelText(result.message)
                    setErrorModel(true);
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }

    }
    const updateRadioBtn = async (cellID, cellValue) => {
        if (userRole !== 'viewer') {
            try {
                const result = await updateRadioBtnRequest(accessToken, username, cellID, cellValue);
                if (result.status === true) {
                    setCellText('');
                    console.log('updateRadioBtn', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const deletePerson = async (cellID, personsID, boardID) => {
        if (userRole !== 'viewer') {
            try {
                const result = await deletePersonRequest(accessToken, username, cellID, personsID, boardID);
                if (result.status === true) {
                    let lists = [...board]
                    lists.map((list, listIndex) => {
                        list.board_list.list_items.map((item, itemIndex) => {
                            item.list_cells.map((cell, cellIndex) => {
                                if (cell.id === cellID) {
                                    lists[listIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value = result.persons
                                }
                            })
                        })
                    })
                    setBoard(lists)
                    console.log('dropdownValueUpdate', result)
                }
                else {
                    setErrorModelText(result.message)
                    setErrorModel(true);
                }
            } catch (err) {
                console.log('catch', err)
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }

    }
    const updateCheckboxes = async (cellID, cellValue, itemIndex, groupIndex, cellIndex, valueIndex, isChecked) => {
        if (userRole !== 'viewer') {
            let lists = [...board]
            if (isChecked) {

                let IdArray = lists[groupIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value

                if (!IdArray.includes(cellValue)) {
                    lists[groupIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value.push(cellValue)

                }
            }
            else {

                lists[groupIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value.map((cell, cellValueIndex) => {

                    if (cell === cellValue) {
                        lists[groupIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value.splice(cellValueIndex, 1)
                    }
                })
            }
            let emptArray = lists[groupIndex].board_list.list_items[itemIndex].list_cells[cellIndex].value
            setBoard(lists)

            try {
                const result = await updateCheckboxesRequest(accessToken, username, cellID, emptArray);
                if (result.status === true) {

                    console.log('updateCheckboxesRequest', result)
                }
            } catch (err) {
                setErrorModelText(err.response.data.message)
                setErrorModel(true);
            }
        }
    }
    const onMouseOver = (e) => {
        if (userRole !== 'viewer') {
            return e.target.style.backgroundColor = '#DCDCDC'
        }

    }
    const onMouseOut = (e) => {
        if (userRole !== 'viewer') {
            return e.target.style.backgroundColor = ''
        }
    }
    return (
        <React.Fragment>
            <div className="col-8 offset-3 mt-2">
                <div className="d-flex justify-content-between align-items-center">

                    <div className="d-inline-flex">
                        {userRole !== 'viewer' ? (
                            <>
                                <Link
                                    to='#'
                                    onClick={confirmBoardDelet}
                                    className='text-danger mx-3 my-3'
                                >
                                    <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                </Link>
                                <Input
                                    style={{ color: 'blue', border: 'white', fontSize: 20, width: 300 }}
                                    value={selectedBoardName}
                                    type="text"
                                    onChange={(e) => handleBoardtext(e)}
                                    onMouseOver={(e) => onMouseOver(e)}
                                    onMouseOut={(e) => onMouseOut(e)}
                                    onBlur={() => UpdateBoard()}
                                />
                            </>
                        ) : (
                            <p style={{ color: 'blue', border: 'white', fontSize: 20, width: 500 }} >
                                {selectedBoardName}
                            </p>
                        )}
                    </div>
                    {userRole !== 'viewer' &&
                    <>
                        <div>
                            <Button
                                type='submit'
                                color='white'
                                onClick={() =>  setInviteModel(true)}
                                className='text-primary'
                            >
                                <span className='px-2'>
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                                        <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Invite member
                            </Button>
                        </div>
 
                        <div>
                            <Button
                                type='submit'
                                color='white'
                                onClick={() => setAddModel(true)}
                                className='text-primary'
                            >
                                <span className='px-2'>
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                                        <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                Add member
                            </Button>
                        </div>
                        <div>
                            <Button
                                type='submit'
                                color='white'
                                onClick={() => setDeleteModel(true)}
                                className='text-primary'
                            >
                                Delete member
                            </Button>
                        </div>
                    </>
                    }

                </div>
                {groupsList.map((groupItem, groupIndex) => (
                    <div className=' border border-primary m-3 p-2'
                        style={{ width: 'max-content',minWidth:'100%' }}
                    >
                        {userRole !== 'viewer' &&
                            <Row key={groupIndex}>
                                <Col className='text-end'>
                                    <Link
                                        to='#'
                                        onClick={() => confirmGroupDelet(groupIndex, groupItem.id)}
                                        className='text-danger mx-5 my-3'
                                    >
                                        <i className='mdi mdi-trash-can font-size-20' id="DeleteGroup"></i>
                                    </Link>
                                </Col>
                            </Row>
                        }
                        <table className="mx-1">
                            <thead >
                                <tr>
                                    {userRole !== 'viewer' &&
                                        <th></th>

                                    }
                                    <th>
                                        <input
                                            style={{ color: 'blue', border: 'white', fontSize: 20, width: 185 }}
                                            value={groupItem.title}
                                            onChange={(e) => updateLocallyGroupName(e, groupIndex)}
                                            className='my-2 '
                                            type="text"
                                            onMouseOver={(e) => onMouseOver(e)}
                                            onMouseOut={(e) => onMouseOut(e)}
                                            disabled={isDisableField}
                                            onBlur={() => updateGroup(groupItem.id)}
                                        />

                                    </th>
                                    {
                                        board.map((board, boardIndex) => (

                                            board.board_list.id === groupItem.id ? (
                                                board.columns.map((headings, headingIndex) => (
                                                    <th>
                                                        {headings.heading && userRole !== 'viewer' &&
                                                            <Link
                                                                to='#'
                                                                onClick={() => confirmColDelete(headings.id)}
                                                                className='text-danger  my-3'
                                                            >
                                                                <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                                            </Link>
                                                        }
                                                        <input
                                                            style={{ border: 'white', width: 174 }}
                                                            value={headings.heading}
                                                            onChange={(event) => updateLocallyCol(boardIndex, headingIndex, event, headings.id)}
                                                            className='my-2 '
                                                            onMouseOver={(e) => onMouseOver(e)}
                                                            onMouseOut={(e) => onMouseOut(e)}
                                                            disabled={isDisableField}
                                                            type="text"
                                                            onBlur={() => updateCol(headings.id)}
                                                        />


                                                    </th>
                                                ))
                                            ) : null
                                        ))}
                                    {userRole !== 'viewer' &&
                                        <th >
                                            {onclickIndex === groupIndex &&
                                                isColLoader === true ? (
                                                <Spinner animation="border" variant="primary" />
                                            ) : (

                                                <i className='mdi mdi-plus-box font-size-20 text-primary ' id={`btn-${groupIndex}`}
                                                    onClick={() => (setOnclickIndex(groupIndex), setDropdownCol(true))}
                                                ></i>
                                            )
                                            }
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={`btn-${groupIndex}`}
                                            >
                                                Add column
                                            </UncontrolledTooltip>

                                            {onclickIndex === groupIndex &&
                                                <Dropdown isOpen={dropdownCol} toggle={() => setDropdownCol(!dropdownCol)} style={{ left: -145 }} >
                                                    <DropdownMenu >
                                                        {colTypes.map((item, index) => (
                                                            <DropdownItem onClick={() => createCol(item.type, item.id, groupIndex)} >
                                                                {item.type}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            }
                                        </th>

                                    }

                                </tr>
                            </thead>

                            {isTableLoader === false ? (
                                board.map((board, boardIndex) => (
                                    board.board_list.id === groupItem.id ? (
                                        board.board_list.list_items.map((row, rowIndex) => (
                                            <tr className=" m-2" key={rowIndex}>
                                                {userRole !== 'viewer' &&
                                                    <td>
                                                        <Link
                                                            to='#'
                                                            onClick={() => confirmRowDelete(boardIndex, rowIndex, row.id)}
                                                            className='text-danger '
                                                        >
                                                            <i className='mdi mdi-trash-can font-size-20' id="DeleteRow"></i>
                                                        </Link>
                                                    </td>
                                                }
                                                <td>
                                                    <input
                                                        value={row.title}
                                                        onChange={(event) => updateLocallyItem(event, boardIndex, rowIndex)}
                                                        className='my-2 '
                                                        type="text"
                                                        onBlur={() => updateItem(row.id)}
                                                        onMouseOver={(e) => onMouseOver(e)}
                                                        onMouseOut={(e) => onMouseOut(e)}
                                                        disabled={isDisableField}
                                                    />
                                                </td>
                                                {row.list_cells.map((cellItem, colIndex) => (
                                                    <td >
                                                        {cellItem.board_column.column_type.type === "Number"
                                                            &&
                                                            <input
                                                                style={{ backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                onChange={(event) => updateLocallyCellText(event.target.value)}
                                                                className='my-2 '
                                                                type="number"
                                                                onBlur={(e) => updateCellText(cellItem.id, e.target.value)}

                                                                onFocus={(e) => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    setCellText(e.target.value)
                                                                }}
                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Priority"
                                                            &&
                                                            <input
                                                                style={{ textAlign: 'center', color: 'white', backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                className='my-2 '
                                                                type="text"
                                                                onFocus={() => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    getColumnValue(cellItem.column, groupItem.id)

                                                                }}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Status"
                                                            &&
                                                            <input
                                                                style={{ textAlign: 'center', color: 'white', backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                className='my-2 '
                                                                type="text"
                                                                onFocus={() => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    getColumnValue(cellItem.column, groupItem.id)
                                                                }}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Date" &&
                                                            <input
                                                                style={{ backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                className='my-2 '
                                                                type="text"
                                                                onFocus={() => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                }}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Person" &&
                                                            <div style={{ backgroundColor: '#DCDCDC', padding: 4, textAlign: 'center' }}
                                                                onClick={
                                                                    () => setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)}
                                                            >
                                                                {/* <i className='mdi mdi-plus-box font-size-20 text-primary mx-3'
                                                                onClick={() => (setOnclickIndex(groupIndex), setDropdownCol(true))}
                                                                ></i> */}
                                                                <Avatar size="25" round={true}
                                                                    email="MF@"
                                                                // facebook-id="invalidfacebookusername"
                                                                // src="https://www.istockphoto.com/en/vector/default-profile-picture-avatar-photo-placeholder-vector-illustration-gm1223671392-359532514"
                                                                />

                                                            </div>
                                                        }
                                                        {cellItem.board_column.column_type.type === "Dropdown" &&
                                                            <input
                                                                style={{ textAlign: 'center', color: 'white', backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                className='my-2 '
                                                                type="text"
                                                                onFocus={() => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    getColumnValue(cellItem.column, groupItem.id)
                                                                }}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Checkboxes" &&
                                                            <div className='d-flex flex-wrap'
                                                                style={{ backgroundColor: '#DCDCDC', padding: 3, textAlign: 'center', width: 212 }} >
                                                                {userRole !== 'viewer' &&
                                                                    <Link
                                                                        to='#'
                                                                        onClick={() => {
                                                                            setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                            getColumnValue(cellItem.column, groupItem.id)
                                                                        }}
                                                                    >
                                                                        <i className='mdi mdi-pencil font-size-20' ></i>
                                                                    </Link>
                                                                }

                                                                {cellItem.board_column.column_values.map((colValue, colValueIndex) => (
                                                                    <div className='d-flex flex-wrap' >

                                                                        {(cellItem.value.includes(colValue.id)) ? (
                                                                            <input
                                                                                className='m-2'
                                                                                type='checkbox'
                                                                                name="checkbox"
                                                                                id='True'
                                                                                value='option2'
                                                                                checked={true}
                                                                                onChange={(e) => {
                                                                                    updateCheckboxes(cellItem.id, colValue.id, rowIndex, groupIndex, colIndex, colValueIndex, false)
                                                                                }}
                                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                                disabled={isDisableField}
                                                                            />
                                                                        ) : (

                                                                            <input
                                                                                className='m-2'
                                                                                type='checkbox'
                                                                                name='checkbox'
                                                                                id='True'
                                                                                value='option2'
                                                                                checked={false}
                                                                                onChange={(e) => {
                                                                                    updateCheckboxes(cellItem.id, colValue.id, rowIndex, groupIndex, colIndex, colValueIndex, true)
                                                                                }}
                                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                                disabled={isDisableField}
                                                                            />
                                                                        )}

                                                                        <p>
                                                                            {colValue.label}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        }
                                                        {cellItem.board_column.column_type.type === "Radio buttons" &&
                                                            <div className='d-flex flex-wrap'
                                                                style={{ backgroundColor: '#DCDCDC', padding: 3, textAlign: 'center', width: 212 }} >
                                                                {userRole !== 'viewer' &&
                                                                    <Link
                                                                        to='#'
                                                                        onClick={() => {
                                                                            setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                            getColumnValue(cellItem.column, groupItem.id)
                                                                        }}
                                                                    >
                                                                        <i className='mdi mdi-pencil font-size-20' ></i>
                                                                    </Link>
                                                                }

                                                                {cellItem.board_column.column_values.map((colValue, colValueIndex) => (
                                                                    <div className='d-flex flex-wrap' >
                                                                        {cellItem.value[0] === colValue.id ? (
                                                                            <input
                                                                                className='m-2'
                                                                                type='radio'
                                                                                name={"radioBtn" + cellItem.id}
                                                                                id='True'
                                                                                value='option2'
                                                                                checked={true}
                                                                                onChange={(e) => {
                                                                                    updateRadioBtn(cellItem.id, colValue.id)
                                                                                    updateLocallyRadioBtn(colValue.id, cellItem.id)
                                                                                }}
                                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                                disabled={isDisableField}
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                className='m-2'
                                                                                type='radio'
                                                                                name={"radioBtn" + cellItem.id}
                                                                                id='True'
                                                                                value='option2'
                                                                                checked={false}
                                                                                onChange={(e) => {
                                                                                    updateRadioBtn(cellItem.id, colValue.id)
                                                                                    updateLocallyRadioBtn(colValue.id, cellItem.id)
                                                                                }}
                                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                                disabled={isDisableField}
                                                                            />
                                                                        )}

                                                                        <p>
                                                                            {colValue.label}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                        {cellItem.board_column.column_type.type === "Long Text" &&
                                                            <textarea
                                                                rows="1" cols="20"
                                                                style={{ backgroundColor: cellItem.value[0].color, padding: 3 }}
                                                                value={cellItem.value[0].value}
                                                                onChange={(event) => updateLocallyCellText(event.target.value)}
                                                                className='my-2 '
                                                                type="text"
                                                                onBlur={(e) => updateCellText(cellItem.id, e.target.value)}
                                                                onFocus={(e) => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    setCellText(e.target.value)
                                                                }}
                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Link" &&
                                                            <div style={{ position: 'relative', }}>
                                                                <span style={{ position: 'absolute', margin: 10, }}><a href={cellItem.value[0].value} target="_blank"> {cellItem.value[0].label} </a></span>
                                                                <input
                                                                    className='my-2 '
                                                                    type="text"

                                                                    onFocus={() => {
                                                                        setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)

                                                                    }}
                                                                    onMouseOver={(e) => onMouseOver(e)}
                                                                    onMouseOut={(e) => onMouseOut(e)}
                                                                    disabled={isDisableField}
                                                                />
                                                            </div>
                                                        }
                                                        {cellItem.board_column.column_type.type === "Duration" &&
                                                            <input
                                                                style={{ backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                onChange={(event) => updateLocallyCellText(event.target.value)}
                                                                className='my-2 '
                                                                type="text"
                                                                onBlur={(e) => updateCellText(cellItem.id, e.target.value)}
                                                                onFocus={(e) => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    setCellText(e.target.value)
                                                                }}
                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Text" &&
                                                            <input
                                                                style={{ backgroundColor: cellItem.value[0].color }}
                                                                value={cellItem.value[0].value}
                                                                onChange={(event) => updateLocallyCellText(event.target.value)}
                                                                className='my-2 '
                                                                type="text"
                                                                onBlur={(e) => updateCellText(cellItem.id, e.target.value)}
                                                                onFocus={(e) => {
                                                                    setStatusRecord(groupIndex, boardIndex, rowIndex, colIndex)
                                                                    setCellText(e.target.value)
                                                                }}
                                                                onMouseOver={(e) => onMouseOver(e)}
                                                                onMouseOut={(e) => onMouseOut(e)}
                                                                disabled={isDisableField}
                                                            />
                                                        }
                                                        {cellItem.board_column.column_type.type === "Person" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true &&
                                                            (<Dropdown isOpen={statusModel} toggle={() => null} >
                                                                <DropdownMenu className='p-2'  >
                                                                    {isEditLabels === false ? (
                                                                        <>
                                                                            {boardUsers.map((colUser, colUsersIndex) => (
                                                                                cellItem.value.includes(colUser.id) &&
                                                                                <DropdownItem  >
                                                                                    <div className='d-flex justify-content-between' >
                                                                                        <p>
                                                                                            {colUser.email}
                                                                                        </p>
                                                                                        <Link
                                                                                            to='#'
                                                                                            onClick={() => deletePerson(cellItem.id, colUser.id, board.board_list.board)}
                                                                                            className='text-danger m-1 '
                                                                                        >
                                                                                            <i className='mdi mdi-trash-can font-size-20' ></i>
                                                                                        </Link>

                                                                                    </div>

                                                                                </DropdownItem>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setStatusModel(false)}
                                                                                >
                                                                                    Close
                                                                                </Button>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(true)}
                                                                                >
                                                                                    + Add
                                                                                </Button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {boardUsers.map((users, usersIndex) => (
                                                                                <DropdownItem className='p-2' onClick={() => addPerson(cellItem.id, users.id, board.board_list.board)}>
                                                                                    {users.email}
                                                                                </DropdownItem>

                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>

                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(false)}
                                                                                >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </DropdownMenu>
                                                            </Dropdown>)
                                                        }
                                                        {cellItem.board_column.column_type.type === "Checkboxes" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true &&
                                                            (<Dropdown isOpen={statusModel} toggle={() => null} >
                                                                <DropdownMenu className='p-2'  >
                                                                    {isEditLabels === false ? (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <DropdownItem  >
                                                                                    <div className='d-flex justify-content-center' >

                                                                                        <p
                                                                                            style={{ height: 33, backgroundColor: colValue.color, width: 100, color: 'white', padding: 4, textAlign: 'center' }}
                                                                                            onClick={() => updateStatus(colValue.label, colValue.color, cellItem.id)}
                                                                                        >
                                                                                            {colValue.label}
                                                                                        </p>


                                                                                    </div>

                                                                                </DropdownItem>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setStatusModel(false)}
                                                                                >
                                                                                    Close
                                                                                </Button>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(true)}
                                                                                >
                                                                                    Edit Labels
                                                                                </Button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <>
                                                                                    <DropdownItem  >
                                                                                        <div className='d-flex justify-content-center' >
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => {
                                                                                                    setColorModel(true)
                                                                                                    dropdownValueIDRecord(groupItem.id, board.board_list.id, cellItem.id, row.id, colValue.id)
                                                                                                    setDropdownText(colValue.label)
                                                                                                }}

                                                                                                className=' m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-format-color-fill mdi-24px'
                                                                                                    style={{ color: colValue.color }}
                                                                                                ></i>
                                                                                            </Link>
                                                                                            <input

                                                                                                placeholder='Add Lable'
                                                                                                value={colValue.label}
                                                                                                onChange={(e) => updateLocallyDropdownValue(e, cellItem.column, colValue.id)}
                                                                                                className='my-2 '
                                                                                                type="text"
                                                                                                onBlur={(e) => dropdownValueUpdate(colValue.id, e.target.value, false)}
                                                                                                onFocus={(e) => {
                                                                                                    setDropdownText(e.target.value)
                                                                                                }}
                                                                                            />
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => dropdownValueDelete(colValue.id, cellItem.column)}
                                                                                                className='text-danger m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-trash-can font-size-20' ></i>
                                                                                            </Link>

                                                                                        </div>
                                                                                    </DropdownItem>
                                                                                    {colValues.length - 1 === colValueIndex &&
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <Button
                                                                                                type='button'
                                                                                                color='black'
                                                                                                onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                                onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                                onClick={() => dropdownValueAdd(cellItem.column, colValue.id)}
                                                                                            >
                                                                                                + New Label
                                                                                            </Button>
                                                                                        </div>}

                                                                                    {dropdownValueID.groupID === groupItem.id &&
                                                                                        dropdownValueID.boardID === board.board_list.id &&
                                                                                        dropdownValueID.cellID === cellItem.id &&
                                                                                        dropdownValueID.rowID === row.id &&
                                                                                        dropdownValueID.cellValueID === colValue.id &&
                                                                                        colorModel === true &&
                                                                                        <Dropdown isOpen={colorModel} toggle={() => setColorModel(!colorModel)} >
                                                                                            <DropdownMenu  >
                                                                                                <DropdownItem >
                                                                                                    <CirclePicker
                                                                                                        onChange={(e) => {

                                                                                                            dropdownValueUpdate(colValue.id, false, e.hex)
                                                                                                            updateLocallyDropdownValueColor(e.hex, cellItem.column, colValue.id)
                                                                                                        }}
                                                                                                    />
                                                                                                </DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </Dropdown>}


                                                                                </>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>

                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(false)}
                                                                                >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </DropdownMenu>
                                                            </Dropdown>)
                                                        }

                                                        {cellItem.board_column.column_type.type === "Radio buttons" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true &&
                                                            (<Dropdown isOpen={statusModel} toggle={() => null} >
                                                                <DropdownMenu className='p-2'  >
                                                                    {isEditLabels === false ? (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <DropdownItem  >
                                                                                    <div className='d-flex justify-content-center' >

                                                                                        <p
                                                                                            style={{ height: 33, backgroundColor: colValue.color, width: 100, color: 'white', padding: 4, textAlign: 'center' }}
                                                                                            onClick={() => updateStatus(colValue.label, colValue.color, cellItem.id)}
                                                                                        >
                                                                                            {colValue.label}
                                                                                        </p>


                                                                                    </div>

                                                                                </DropdownItem>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setStatusModel(false)}
                                                                                >
                                                                                    Close
                                                                                </Button>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(true)}
                                                                                >
                                                                                    Edit Labels
                                                                                </Button>

                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <>
                                                                                    <DropdownItem  >
                                                                                        <div className='d-flex justify-content-center' >
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => {
                                                                                                    setColorModel(true)
                                                                                                    dropdownValueIDRecord(groupItem.id, board.board_list.id, cellItem.id, row.id, colValue.id)
                                                                                                    setDropdownText(colValue.label)
                                                                                                }}

                                                                                                className=' m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-format-color-fill mdi-24px'
                                                                                                    style={{ color: colValue.color }}
                                                                                                ></i>
                                                                                            </Link>
                                                                                            <input

                                                                                                placeholder='Add Lable'
                                                                                                value={colValue.label}
                                                                                                onChange={(e) => updateLocallyDropdownValue(e, cellItem.column, colValue.id)}
                                                                                                className='my-2 '
                                                                                                type="text"
                                                                                                onBlur={(e) => dropdownValueUpdate(colValue.id, e.target.value, false)}
                                                                                                onFocus={(e) => {
                                                                                                    setDropdownText(e.target.value)
                                                                                                }}
                                                                                            />
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => dropdownValueDelete(colValue.id, cellItem.column)}
                                                                                                className='text-danger m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-trash-can font-size-20' ></i>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </DropdownItem>
                                                                                    {colValues.length - 1 === colValueIndex &&
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <Button
                                                                                                type='button'
                                                                                                color='black'
                                                                                                onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                                onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                                onClick={() => dropdownValueAdd(cellItem.column, colValue.id)}
                                                                                            >
                                                                                                + New Label
                                                                                            </Button>
                                                                                        </div>}

                                                                                    {dropdownValueID.groupID === groupItem.id &&
                                                                                        dropdownValueID.boardID === board.board_list.id &&
                                                                                        dropdownValueID.cellID === cellItem.id &&
                                                                                        dropdownValueID.rowID === row.id &&
                                                                                        dropdownValueID.cellValueID === colValue.id &&
                                                                                        colorModel === true &&
                                                                                        <Dropdown isOpen={colorModel} toggle={() => setColorModel(!colorModel)} >
                                                                                            <DropdownMenu  >
                                                                                                <DropdownItem >
                                                                                                    <CirclePicker
                                                                                                        onChange={(e) => {

                                                                                                            dropdownValueUpdate(colValue.id, false, e.hex)
                                                                                                            updateLocallyDropdownValueColor(e.hex, cellItem.column, colValue.id)
                                                                                                        }}
                                                                                                    />
                                                                                                </DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </Dropdown>}


                                                                                </>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>

                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(false)}
                                                                                >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </DropdownMenu>
                                                            </Dropdown>)
                                                        }
                                                        {cellItem.board_column.column_type.type === "Link" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true &&
                                                            (<Dropdown isOpen={statusModel} toggle={() => null} >
                                                                <DropdownMenu className='p-2'  >
                                                                    <h5>Web address</h5>
                                                                    <DropdownItem  >
                                                                        <input
                                                                            value={cellLink}
                                                                            onChange={(e) => setCellLink(e.target.value)}
                                                                            className='my-2 '
                                                                            type="text"
                                                                        />

                                                                    </DropdownItem>
                                                                    <h5>Text to display </h5>
                                                                    <DropdownItem  >
                                                                        <input
                                                                            value={cellLinkText}
                                                                            onChange={(e) => setCellLinkText(e.target.value)}
                                                                            className='my-2 '
                                                                            type="text"
                                                                        />

                                                                    </DropdownItem>

                                                                    <hr />

                                                                    <div className='d-flex justify-content-center'>
                                                                        <Button
                                                                            type='button'
                                                                            color='black'
                                                                            onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                            onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                            onClick={() => updateCellLink(cellItem.id)}
                                                                        >
                                                                            Apply
                                                                        </Button>
                                                                        <Button
                                                                            type='button'
                                                                            color='black'
                                                                            onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                            onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                            onClick={() => setStatusModel(false)}
                                                                        >
                                                                            Close
                                                                        </Button>
                                                                    </div>

                                                                </DropdownMenu>
                                                            </Dropdown>)
                                                        }
                                                        {cellItem.board_column.column_type.type === "Dropdown" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true &&
                                                            (<Dropdown isOpen={statusModel} toggle={() => null} >
                                                                <DropdownMenu className='p-2'  >
                                                                    {isEditLabels === false ? (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <DropdownItem  >
                                                                                    <div className='d-flex justify-content-center' >
                                                                                        <p
                                                                                            style={{ height: 33, backgroundColor: colValue.color, width: 100, color: 'white', padding: 4, textAlign: 'center' }}
                                                                                            onClick={() => updateStatus(colValue.label, colValue.color, cellItem.id)}
                                                                                        >
                                                                                            {colValue.label}
                                                                                        </p>


                                                                                    </div>

                                                                                </DropdownItem>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(true)}
                                                                                >
                                                                                    Edit Labels
                                                                                </Button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <>
                                                                                    <DropdownItem  >
                                                                                        <div className='d-flex justify-content-center' >
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => {
                                                                                                    setColorModel(true)
                                                                                                    dropdownValueIDRecord(groupItem.id, board.board_list.id, cellItem.id, row.id, colValue.id)
                                                                                                    setDropdownText(colValue.label)
                                                                                                }}

                                                                                                className=' m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-format-color-fill mdi-24px' 
                                                                                                    style={{ color: colValue.color }}
                                                                                                ></i>
                                                                                            </Link>
                                                                                            <input

                                                                                                placeholder='Add Lable'
                                                                                                value={colValue.label}
                                                                                                onChange={(e) => updateLocallyDropdownValue(e, cellItem.column, colValue.id)}
                                                                                                className='my-2 '
                                                                                                type="text"
                                                                                                onBlur={(e) => dropdownValueUpdate(colValue.id, e.target.value, false)}
                                                                                                onFocus={(e) => {
                                                                                                    setDropdownText(e.target.value)
                                                                                                }}
                                                                                            />


                                                                                          
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => validateIsUseValue(colValue.label, colValue.id, cellItem.column)}
                                                                                                className='text-danger m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-trash-can font-size-20' ></i>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </DropdownItem>
                                                                                    {colValues.length - 1 === colValueIndex &&
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <Button
                                                                                                type='button'
                                                                                                color='black'
                                                                                                onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                                onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                                onClick={() => dropdownValueAdd(cellItem.column, colValue.id)}
                                                                                            >
                                                                                                + New Label
                                                                                            </Button>
                                                                                        </div>}

                                                                                    {dropdownValueID.groupID === groupItem.id &&
                                                                                        dropdownValueID.boardID === board.board_list.id &&
                                                                                        dropdownValueID.cellID === cellItem.id &&
                                                                                        dropdownValueID.rowID === row.id &&
                                                                                        dropdownValueID.cellValueID === colValue.id &&
                                                                                        colorModel === true &&
                                                                                        <Dropdown isOpen={colorModel} toggle={() => setColorModel(!colorModel)} >
                                                                                            <DropdownMenu  >
                                                                                                <DropdownItem >
                                                                                                    <CirclePicker
                                                                                                        onChange={(e) => {

                                                                                                            dropdownValueUpdate(colValue.id, false, e.hex)
                                                                                                            updateLocallyDropdownValueColor(e.hex, cellItem.column, colValue.id)
                                                                                                        }}
                                                                                                    />
                                                                                                </DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </Dropdown>}


                                                                                </>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>

                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(false)}
                                                                                >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </DropdownMenu>
                                                            </Dropdown>)
                                                        }
                                                        {cellItem.board_column.column_type.type === "Priority" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true &&
                                                            (<Dropdown isOpen={statusModel} toggle={() => null} >
                                                                <DropdownMenu className='p-2'  >
                                                                    {isEditLabels === false ? (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <DropdownItem  >
                                                                                    <div className='d-flex justify-content-center' >
                                                                                        <p
                                                                                            style={{ height: 33, backgroundColor: colValue.color, width: 100, color: 'white', padding: 4, textAlign: 'center' }}
                                                                                            onClick={() => updateStatus(colValue.label, colValue.color, cellItem.id)}
                                                                                        >
                                                                                            {colValue.label}
                                                                                        </p>


                                                                                    </div>

                                                                                </DropdownItem>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(true)}
                                                                                >
                                                                                    Edit Labels
                                                                                </Button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {colValues.map((colValue, colValueIndex) => (
                                                                                <>
                                                                                    <DropdownItem  >
                                                                                        <div className='d-flex justify-content-center' >
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => {
                                                                                                    setColorModel(true)
                                                                                                    dropdownValueIDRecord(groupItem.id, board.board_list.id, cellItem.id, row.id, colValue.id)
                                                                                                    setDropdownText(colValue.label)
                                                                                                }}

                                                                                                className=' m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-format-color-fill mdi-24px'
                                                                                                    style={{ color: colValue.color }}
                                                                                                ></i>
                                                                                            </Link>
                                                                                            <input

                                                                                                placeholder='Add Lable'
                                                                                                value={colValue.label}
                                                                                                onChange={(e) => updateLocallyDropdownValue(e, cellItem.column, colValue.id)}
                                                                                                className='my-2 '
                                                                                                type="text"
                                                                                                onBlur={(e) => dropdownValueUpdate(colValue.id, e.target.value, false)}
                                                                                                onFocus={(e) => {
                                                                                                    setDropdownText(e.target.value)
                                                                                                }}
                                                                                            />
                                                                                            <Link
                                                                                                to='#'
                                                                                                onClick={() => validateIsUseValue(colValue.label, colValue.id, cellItem.column) }
                                                                                                className='text-danger m-1 '
                                                                                            >
                                                                                                <i className='mdi mdi-trash-can font-size-20' ></i>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </DropdownItem>
                                                                                    {colValues.length - 1 === colValueIndex &&
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <Button
                                                                                                type='button'
                                                                                                color='black'
                                                                                                onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                                onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                                onClick={() => dropdownValueAdd(cellItem.column, colValue.id)}
                                                                                            >
                                                                                                + New Label
                                                                                            </Button>
                                                                                        </div>}

                                                                                    {dropdownValueID.groupID === groupItem.id &&
                                                                                        dropdownValueID.boardID === board.board_list.id &&
                                                                                        dropdownValueID.cellID === cellItem.id &&
                                                                                        dropdownValueID.rowID === row.id &&
                                                                                        dropdownValueID.cellValueID === colValue.id &&
                                                                                        colorModel === true &&
                                                                                        <Dropdown isOpen={colorModel} toggle={() => setColorModel(!colorModel)} >
                                                                                            <DropdownMenu  >
                                                                                                <DropdownItem >
                                                                                                    <CirclePicker
                                                                                                        onChange={(e) => {

                                                                                                            dropdownValueUpdate(colValue.id, false, e.hex)
                                                                                                            updateLocallyDropdownValueColor(e.hex, cellItem.column, colValue.id)
                                                                                                        }}
                                                                                                    />
                                                                                                </DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </Dropdown>}


                                                                                </>
                                                                            ))}
                                                                            <hr />
                                                                            <div className='d-flex justify-content-center'>

                                                                                <Button
                                                                                    type='button'
                                                                                    color='black'
                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                    onClick={() => setIsEditLabels(false)}
                                                                                >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </>

                                                                    )}
                                                                </DropdownMenu>
                                                            </Dropdown>)
                                                        }
                                                        {cellItem.board_column.column_type.type === "Status" &&
                                                            userRole !== 'viewer' &&
                                                            statusIndex.groupIndex === groupIndex &&
                                                            statusIndex.boardIndex === boardIndex &&
                                                            statusIndex.rowIndex === rowIndex &&
                                                            statusIndex.colIndex === colIndex &&
                                                            statusModel === true
                                                            ?
                                                            (
                                                                <Dropdown isOpen={statusModel} toggle={() => null} >
                                                                    <DropdownMenu className='p-2'  >
                                                                        {isEditLabels === false ? (
                                                                            <>
                                                                                {colValues.map((colValue, colValueIndex) => (
                                                                                    <DropdownItem  >
                                                                                        <div className='d-flex justify-content-center' >
                                                                                            <p
                                                                                                style={{ height: 33, backgroundColor: colValue.color, width: 100, color: 'white', padding: 4, textAlign: 'center' }}
                                                                                                onClick={() => updateStatus(colValue.label, colValue.color, cellItem.id)}
                                                                                            >
                                                                                                {colValue.label}
                                                                                            </p>


                                                                                        </div>

                                                                                    </DropdownItem>
                                                                                ))}
                                                                                <hr />
                                                                                <div className='d-flex justify-content-center'>
                                                                                    <Button
                                                                                        type='button'
                                                                                        color='black'
                                                                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                        onClick={() => setIsEditLabels(true)}
                                                                                    >
                                                                                        Edit Labels
                                                                                    </Button>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {colValues.map((colValue, colValueIndex) => (
                                                                                    <>
                                                                                        <DropdownItem  >
                                                                                            <div className='d-flex justify-content-center' >
                                                                                                <Link
                                                                                                    to='#'
                                                                                                    onClick={() => {
                                                                                                        setColorModel(true)
                                                                                                        dropdownValueIDRecord(groupItem.id, board.board_list.id, cellItem.id, row.id, colValue.id)
                                                                                                        setDropdownText(colValue.label)
                                                                                                    }}

                                                                                                    className=' m-1 '
                                                                                                >
                                                                                                    <i className='mdi mdi-format-color-fill mdi-24px'
                                                                                                        style={{ color: colValue.color }}
                                                                                                    ></i>
                                                                                                </Link>
                                                                                                <input

                                                                                                    placeholder='Add Lable'
                                                                                                    value={colValue.label}
                                                                                                    onChange={(e) => updateLocallyDropdownValue(e, cellItem.column, colValue.id)}
                                                                                                    className='my-2 '
                                                                                                    type="text"
                                                                                                    onBlur={(e) => dropdownValueUpdate(colValue.id, e.target.value, false)}
                                                                                                    onFocus={(e) => {
                                                                                                        setDropdownText(e.target.value)
                                                                                                    }}
                                                                                                />
                                                                                                <Link
                                                                                                    to='#'
                                                                                                    onClick={() => validateIsUseValue(colValue.label, colValue.id, cellItem.column)}
                                                                                                    className='text-danger m-1 '
                                                                                                >
                                                                                                    <i className='mdi mdi-trash-can font-size-20' ></i>
                                                                                                </Link>
                                                                                            </div>
                                                                                            
                                                                                        </DropdownItem>
                                                                                        {colValues.length - 1 === colValueIndex &&
                                                                                            <div className='d-flex justify-content-center'>
                                                                                                <Button
                                                                                                    type='button'
                                                                                                    color='black'
                                                                                                    onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                                    onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                                    onClick={() => dropdownValueAdd(cellItem.column, colValue.id)}
                                                                                                >
                                                                                                    + New Label
                                                                                                </Button>
                                                                                            </div>
                                                                                        }

                                                                                        {dropdownValueID.groupID === groupItem.id &&
                                                                                            dropdownValueID.boardID === board.board_list.id &&
                                                                                            dropdownValueID.cellID === cellItem.id &&
                                                                                            dropdownValueID.rowID === row.id &&
                                                                                            dropdownValueID.cellValueID === colValue.id &&
                                                                                            colorModel === true &&
                                                                                            <Dropdown isOpen={colorModel} toggle={() => setColorModel(!colorModel)} >
                                                                                                <DropdownMenu  >
                                                                                                    <DropdownItem  >
                                                                                                        <CirclePicker
                                                                                                            onChange={(e) => {

                                                                                                                dropdownValueUpdate(colValue.id, false, e.hex)
                                                                                                                updateLocallyDropdownValueColor(e.hex, cellItem.column, colValue.id)
                                                                                                            }}
                                                                                                        />
                                                                                                    </DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </Dropdown>}


                                                                                    </>
                                                                                ))}
                                                                                <hr />
                                                                                <div className='d-flex justify-content-center'>

                                                                                    <Button
                                                                                        type='button'
                                                                                        color='black'
                                                                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                                                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                                                                        onClick={() => setIsEditLabels(false)}
                                                                                    >
                                                                                        Apply
                                                                                    </Button>
                                                                                </div>
                                                                            </>

                                                                        )}
                                                                    </DropdownMenu>
                                                                </Dropdown>) :
                                                            cellItem.board_column.column_type.type === "Date" &&
                                                                userRole !== 'viewer' &&
                                                                statusIndex.groupIndex === groupIndex &&
                                                                statusIndex.boardIndex === boardIndex &&
                                                                statusIndex.rowIndex === rowIndex &&
                                                                statusIndex.colIndex === colIndex &&
                                                                statusModel === true
                                                                ? (
                                                                    <Modal
                                                                        size='md'
                                                                        isOpen={statusModel}
                                                                        centered={true}
                                                                        toggle={null}
                                                                    >
                                                                        <ModalHeader
                                                                            toggle={() => setStatusModel(!statusModel)}
                                                                        >
                                                                            Select Date
                                                                        </ModalHeader>
                                                                        <ModalBody className="d-flex justify-content-center">
                                                                            <Calendar
                                                                                onChange={onChangeDate}
                                                                                value={date}
                                                                            />
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <div className='text-end'>
                                                                                <Button
                                                                                    color='secondary'
                                                                                    onClick={() => setStatusModel(!statusModel)}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </div>
                                                                            <div className='text-end'>
                                                                                <Button
                                                                                    type='button'
                                                                                    color='primary'
                                                                                    onClick={() => updateCellDate(cellItem.id)}
                                                                                >
                                                                                    OK
                                                                                </Button>
                                                                            </div>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                )
                                                                : null
                                                        }
                                                    </td>
                                                ))
                                                }

                                            </tr>
                                        ))
                                    ) : null

                                ))
                            ) : (
                                <div>
                                    <Spinner animation="border" variant="primary" />
                                </div>

                            )}
                        </table>
                        {userRole !== 'viewer' &&
                            <Row style={{ width: 900 }} >
                                <Col sm={9} className="mt-4">
                                    <Input
                                        id='itemText'
                                        placeholder="+ Add Item"

                                        value={
                                            itemText !== '' && statusIndex.groupIndex === groupIndex
                                                ? itemText
                                                : ""
                                        }
                                        type="text"
                                        onChange={(e) => handleText(e)}
                                        onMouseOver={(e) => e.target.style.borderColor = 'black'}
                                        onMouseOut={(e) => e.target.style.borderColor = ''}
                                        onFocus={() => setStatusRecord(groupIndex)}
                                        onKeyPress={(e) => onKeyPressAddRow(e, groupItem.id)}

                                    />
                                </Col>
                                {itemText && statusIndex.groupIndex === groupIndex &&
                                    isRowLoader === false &&
                                    <Col sm={3} className="mt-1">
                                        <Link
                                            to='#'
                                            onClick={() => createItem(groupItem.id, groupIndex)}
                                            className='text-primary '
                                        >
                                            <UncontrolledTooltip placement="top" target={`btn-${groupItem.id}`} >
                                                Add Row
                                            </UncontrolledTooltip>


                                            <i className='mdi mdi-plus-box mdi-48px ' id={`btn-${groupItem.id}`} />
                                        </Link>
                                    </Col>
                                }
                                {isRowLoader === true && statusIndex.groupIndex === groupIndex &&
                                    <Col sm={3} className="mt-4">
                                        <Spinner animation="border" variant="primary" />
                                    </Col>
                                }
                            </Row>
                        }

                    </div>
                ))}
                {userRole !== 'viewer' &&
                    <Row className="mx-3, mb-5" >
                        {isShowGroupField === false ? (

                            <Col className="m-3">
                                <Button
                                    type='button'
                                    color='primary'
                                    onClick={() => setIsShowGroupField(true)}
                                >
                                    + Add List
                                </Button>
                            </Col>
                        ) : (
                            <>
                                <Col sm={10} className="m-3">
                                    <Input
                                        id="groupTitle"
                                        value={groupName}
                                        placeholder="+ Add List"
                                        type="text"
                                        onChange={(e) => handleText(e)}
                                        onMouseOver={(e) => e.target.style.borderColor = 'black'}
                                        onMouseOut={(e) => e.target.style.borderColor = ''}
                                        onKeyPress={(e) => onKeyPressAddGroup(e)}
                                        autoFocus={true}

                                    />
                                </Col>
                                {groupName && isGroupLoader === false &&
                                    <Col className="mt-1">
                                        <Link
                                            to='#'
                                            onClick={createGroup}
                                            className='text-primary '
                                        >
                                            <UncontrolledTooltip placement="top" target="AddGroup" >
                                                Add New Group
                                            </UncontrolledTooltip>
                                            <i className='mdi mdi-plus-box  mdi-48px' id="AddGroup"></i>
                                        </Link>
                                    </Col>
                                }
                                {isGroupLoader === true &&
                                    <Col className="mt-4">
                                        <Spinner animation="border" variant="primary" />
                                    </Col>
                                }
                            </>
                        )}
                    </Row>
                }


                {alertArray.confirm_both === true &&
                    <SweetAlert
                        title='Are you sure?'
                        warning
                        showCancel
                        confirmBtnBsStyle='success'
                        cancelBtnBsStyle='danger'
                        confirmBtnText='YES'
                        cancelBtnText='NO'
                        onConfirm={() =>
                            setAlertArray({
                                confirm_both: false,
                                success_dlg: true,
                                dynamic_title: 'Deleted',
                                dynamic_description: 'Your Record has been deleted.',
                            })
                        }
                        onCancel={() =>
                            setAlertArray({
                                confirm_both: false,
                                error_dlg: true,
                                dynamic_title: 'Cancelled',
                                dynamic_description: 'Your Record is safe :)',
                            })

                        }
                    >
                        You want to delete permanently
                    </SweetAlert>
                }
                {alertArray.success_dlg ? (
                    groupItemRecord.groupDel === true ? (
                        <SweetAlert
                            success
                            title={alertArray.dynamic_title}
                            onConfirm={deleteGroup}
                        >   {alertArray.dynamic_description}
                        </SweetAlert>
                    ) : groupItemRecord.rowDel === true ? (
                        <SweetAlert
                            success
                            title={alertArray.dynamic_title}
                            onConfirm={deleteItem}
                        >   {alertArray.dynamic_description}
                        </SweetAlert>
                    ) : groupItemRecord.colDel === true ? (
                        <SweetAlert
                            success
                            title={alertArray.dynamic_title}
                            onConfirm={deleteColm}
                        >   {alertArray.dynamic_description}
                        </SweetAlert>
                    ) : boardDelModel === true ? (
                        <SweetAlert
                            success
                            title={alertArray.dynamic_title}
                            onConfirm={deleteBoard}
                        >   {alertArray.dynamic_description}
                        </SweetAlert>) : null

                ) : null}
                {alertArray.error_dlg ? (
                    <SweetAlert
                        error
                        title={alertArray.dynamic_title}
                        onConfirm={() => setAlertArray({
                            error_dlg: false
                        })}
                    >x
                        {alertArray.dynamic_description}
                    </SweetAlert>
                ) : null}
                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={3000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}

                <Modal
                    size='md'
                    isOpen={inviteModel}
                    centered={true}
                    toggle={() => setInviteModel(!inviteModel)}
                >
                    <ModalHeader
                        toggle={() => setInviteModel(!inviteModel)}
                    >
                        Invite Board Members
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Input
                                            name='InviteEmail'
                                            type="email"
                                            id='name'
                                            placeholder='Enter email'
                                            value={inviteEmail}
                                            onChange={handleInvitationText}
                                        />
                                    </div>
                                    <div>
                                        <Label>
                                            Select Role
                                        </Label>
                                        <Select
                                            value={selectedRole}
                                            onChange={handleSelectedRole}
                                            options={roleOption}
                                            classNamePrefix='select2-selection'

                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className='text-end'>
                            <Button
                                color='secondary'
                                onClick={() => setInviteModel(!inviteModel)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='text-end'>
                            <Button
                                type='button'
                                color='primary'
                                onClick={InviteNow}
                            >
                                Invite a new member by email
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                {successModel === true ? (
                    <SweetAlert
                        title={successModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        success
                        onConfirm={() => setSuccessModel(false)}

                    ></SweetAlert>
                ) : (null)}
                <Modal
                    size='md'
                    isOpen={addModel}
                    centered={true}
                    toggle={() => setAddModel(!addModel)}
                >
                    <ModalHeader
                        toggle={() => setAddModel(!addModel)}
                    >
                        Add member in Team
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>
                                            Select mail
                                        </Label>
                                        <Select
                                            placeholder={dropdownTitle}
                                            value={selectedMember}
                                            options={memberOptions}
                                            onChange={handleMembers}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <Label>
                                            Select Role
                                        </Label>
                                        <Select
                                            value={selectedRole}
                                            onChange={handleSelectedRole}
                                            options={roleOption}
                                            classNamePrefix='select2-selection'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className='text-end'>
                            <Button
                                color='secondary'
                                onClick={() => setAddModel(!addModel)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='text-end'>
                            <Button
                                type='button'
                                color='primary'
                                // onClick={() => setAddModel(!addModel)}

                                onClick={addNewMemberBymail}
                            >
                                Add  member by email
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal
                    size='md'
                    isOpen={deleteModel}
                    centered={true}
                    toggle={() => setDeleteModel(!deleteModel)}
                >
                    <ModalHeader
                        toggle={() => setDeleteModel(!deleteModel)}
                    >
                        Delete member in Board
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>
                                            Select mail
                                        </Label>
                                        <Select
                                            placeholder={dropdownTitle}
                                            value={selectedMember}
                                            options={boardMemberOptions}
                                            onChange={handleMembers}
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <div className='text-end'>
                            <Button
                                color='secondary'
                                onClick={() => setDeleteModel(!deleteModel)}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className='text-end'>
                            <Button
                                type='button'
                                color='primary'
                                // onClick={() => setDeleteModel(!deleteModel)}

                            onClick={deleteBoardMember}
                            >
                                Delete  member
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

        </React.Fragment>
    );
}

export default BoardComponents;