import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate, useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import {
    getEveryoneUsersRequest,
    getAcceptedUsersAtEverone,
    getPendingUsersAtEverone,
    getRejectedUsersAtEverone
} from '../../../Services/AdminServices/TeamServices'
import {

    Container,
    Spinner
} from 'reactstrap'
const TeamDashboard = (props) => {
    const navigate = useNavigate()
    const { username, id ,slug} = useParams();
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, userName } = transformedData;
    const [allusers, setAllUsers] = useState([])
    const [isShowAllUsers, setIsShowAllUsers] = useState(false);
    const [isShowPendingUsers, setIsShowPendingUsers] = useState(false);
    const [isShowAcceptedUsers, setIsShowAcceptedUsers] = useState(false);
    const [isShowRejectedUsers, setIsShowRejectedUsers] = useState(false);
    const [acceptedusers, setAcceptedusers] = useState([]);
    const [rejectedusers, setRejectedusers] = useState([]);
    const [pendingusers, setPendingusers] = useState([]);
    // useEffect(() => {
    //     getEveryoneUsers()
    // }, [])
    useEffect(() => {
        if (slug === 'accepted') {
            getAcceptedUsers()
        }
        else if (slug === 'everyone') {
            getEveryoneUsers()
        }
        else if (slug === 'rejected') {
            getRejectedUsers()
        }
        else if (slug === 'pending') {
            getPendingUsers()
        }
       

    }, [id, slug]);
    const getEveryoneUsers = async () => {
        try {
            setIsShowAllUsers(true)
            const result = await getEveryoneUsersRequest(accessToken, username);
            if (result.status === true) {
                setAllUsers(result.data);
                setIsShowAllUsers(false)

            }

        } catch (err) {
            console.log('errrrrror', err)
        }
    }
    const getAcceptedUsers = async () => {
        try {
            setIsShowAcceptedUsers(true)
            const result = await getAcceptedUsersAtEverone(accessToken, username);
            if (result.status === true) {
                console.log('jvsdgskjdkjsdh',result)
                setAcceptedusers(result.data)
                
                setIsShowAcceptedUsers(false)

            }

        } catch (err) {
            console.log('errrrrror', err)
        }
    }
    const getRejectedUsers = async () => {
        try {
            
            
            setIsShowRejectedUsers(true)
            const result = await getRejectedUsersAtEverone(accessToken, username);
            if (result.status === true) {
                setRejectedusers(result.data)
                
                setIsShowRejectedUsers(false)

            }

        } catch (err) {
            console.log('errrrrror', err)
        }
    }
    const getPendingUsers = async () => {
        try {
            setIsShowPendingUsers(true)
            const result = await getPendingUsersAtEverone(accessToken, username);
            if (result.status === true) {
                setPendingusers(result.data);
                setIsShowPendingUsers(false)

            }

        } catch (err) {
            console.log('errrrrror', err)
        }
    }


    return (
        <div className="container-fluid">
            {isShowAllUsers === false ? (
                <div className="col-9 offset-3 mt-2">
                    <b>Every One at {username}</b>
                    <div className='d-flex justify-content-center mt-4'>
                        <div className='m-4'>
                            <button
                                className="btn btn-light text-primary"
                                type="button"
                                style={{ borderColor: slug === 'everyone' ? 'blue' : '' }}
                                onClick={() => {

                                    navigate(`/${username}/teams/everyone`)
                                }}
                            >
                                All
                            </button>
                        </div>
                        <div className='m-4'>
                            <button
                                className="btn btn-light text-primary"
                                type="button"
                                style={{ borderColor: slug === 'accepted' ? 'blue' : '' }}

                                onClick={() => {

                                    navigate(`/${username}/teams/accepted`)

                                }}
                            >
                                Accepted
                            </button>
                        </div>
                        <div className='m-4'>
                            <button
                                className="btn btn-light text-primary"
                                type="button"
                                style={{ borderColor: slug === 'rejected' ? 'blue' : '' }}

                                onClick={() => {



                                    navigate(`/${username}/teams/rejected`)
                                }}
                            >
                                Rejected
                            </button>
                        </div>
                        <div className='m-4'>
                            <button
                                className="btn btn-light text-primary"
                                type="button"
                                style={{ borderColor: slug === 'pending' ? 'blue' : '' }}

                                onClick={() => {



                                    navigate(`/${username}/teams/pending`)
                                }}
                            >
                                Pending
                            </button>
                        </div>
                    </div>
                    <table className="mx-3 w-100 mt-5" >
                        <thead>
                            <tr className='border-bottom border-secondary'>
                                <th className='py-4' >Email</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                slug === 'everyone' ? (
                                    isShowAllUsers === false ? (
                                        allusers.map((item, index) => (
                                            <tr className='border-bottom border-secondary ' key={index}

                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >
                                                <td className='text-primary py-4' >{item.email} </td>
                                                <td className='text-primary py-4' >{item.pivot.invitation_status} </td>
                                                {/* <td >
                                        <Link
                                            to='#'
                                            onClick={() => deleteTeamMember(item.id)}
                                            className='text-danger mx-5 my-3'
                                        >
                                            <i className='mdi mdi-trash-can font-size-20' id="DeleteCol"></i>
                                        </Link>
                                    </td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <div className='d-flex justify-content-center'>

                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    )
                                ) : slug === 'accepted' ? (
                                    isShowAcceptedUsers === false ? (
                                        acceptedusers.map((item, index) => (

                                            <tr className='border-bottom border-secondary ' key={index}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >
                                                <td className='text-primary py-4' >{item.email} </td>
                                                <td className='text-primary py-4' >{item.pivot.invitation_status} </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <div className='d-flex justify-content-center'>

                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    )

                                ) : slug === 'rejected' ? (
                                    isShowRejectedUsers === false ? (
                                        rejectedusers.map((item, index) => (
                                            <tr className='border-bottom border-secondary ' key={index}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >
                                                <td className='text-primary py-4' >{item.email} </td>
                                                <td className='text-primary py-4' >{item.pivot.invitation_status} </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <div className='d-flex justify-content-center'>

                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    )
                                ) : slug === 'pending' ? (
                                    isShowPendingUsers === false ? (
                                        pendingusers.map((item, index) => (
                                            <tr className='border-bottom border-secondary ' key={index}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate(`/${username}/profile/${item.id}`)
                                                }}
                                            >
                                                <td className='text-primary py-4' >{item.email} </td>
                                                <td className='text-primary py-4' >{item.pivot.invitation_status} </td>

                                            </tr>
                                        ))
                                    ) : (<div className='d-flex justify-content-center'>

                                        <Spinner animation="border" variant="primary" />
                                    </div>)
                                ) : (null)


                            }

                        </tbody>

                    </table>
                </div>
            ) : (
                <div className='d-flex justify-content-center'>

                    <Spinner animation="border" variant="primary" />
                </div>
            )}
        </div>
              
    );
}

export default TeamDashboard;