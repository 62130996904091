import React, { useState, useEffect } from 'react';
import { Link, useNavigate,useParams,useLocation } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { format } from "date-fns";
import {
  getFirstTenNotificationRequest,
  acceptNotificationRequest,
  rejectNotificationRequest,
  markReadNotificationRequest
} from '../../../Services/AdminServices/NotificationServices'
import "react-bootstrap-submenu/dist/index.css"
import { DropdownSubmenu, NavDropdownMenu, } from "react-bootstrap-submenu";
import { NavDropdown } from "react-bootstrap";
import { logOutUser, getOwnersRequest } from '../../../Services/AuthServices'
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
const MinSideBar = (props) => {
  const navigate = useNavigate();
  let {username} = useParams();
  
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isInnerDropdown, setIsInnerDropdown] = useState(false)
  const [isOpenNotification, setIsNotification] = useState(false)
  const userData = localStorage.getItem("userData");
  const transformedData = JSON.parse(userData);
  const { accessToken, loginUserID } = transformedData;
  const [errorModelText, setErrorModelText] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [owners, setOwners] = useState([]);
  useEffect(() => {
    getOwners();
    getNotifications()
  }, [isOpenNotification]);
  const getOwners = async () => {
    try {
      const result = await getOwnersRequest(accessToken);

      if (result.status === true) {
        setOwners(result.data)
        // console.log('owners', result.data);
      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  const getNotifications = async () => {
    try {
      const result = await getFirstTenNotificationRequest(accessToken,10);
      if (result.status === true) {
        setNotificationData(result.notifications.data)
       
      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  const acceptNotification = async (code, id) => {
    try {

      const result = await acceptNotificationRequest(accessToken, code, id);
      console.log('acceptNotification', result)
      if (result.status === true) {
        getNotifications()
        // setNotificationData(result.notifications)
      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  const rejectNotification = async (id) => {
    try {
      const result = await rejectNotificationRequest(accessToken, id);
      console.log('rejectNotification', result)
      if (result.status === true) {
        getNotifications()

        // setNotificationData(result.notifications)
      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  const logOut = async () => {
    try {
      const result = await logOutUser(accessToken);

      if (result.status === 'success') {
        setIsOpenDropdown(false)
        navigate("/")
        localStorage.clear();
      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  const handleOwners = (name, role) => {
    setIsOpenDropdown(false)
    localStorage.setItem(
      "userData",
      JSON.stringify({
        accessToken: accessToken,
        userName: name,
        role: role,
        loginUserID: loginUserID

      })
    );
    localStorage.removeItem("team_path");

    navigate(`/${name}/workspaces`);
    
  }
  const timeFormate = (time) => {
    var date = new Date(time);

    var formattedDate = format(date, "MMMM do, yyyy H:mma");

    return formattedDate;
  }
  const markAsReadNotification = async (id) => {
    try {
      const result = await markReadNotificationRequest(accessToken, id);
      if (result.status === true) {
        console.log('result', result)
        getNotifications()

        // setNotificationData(result.notifications)
      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  

  return (
    <div  >
      <div className='mt-4' >
        <Link
          to={localStorage.getItem("workspace_path") ?? `/${username}/workspaces` }
          className='text-white mx-2'
        >
          <i className='mdi mdi-home mdi-36px' ></i>
        </Link>
        <Link
          to={localStorage.getItem("team_path") ?? `/${username}/teams/all`}
          className='text-white mx-2'
        >
          <i className='mdi mdi-account-multiple-outline mdi-36px' ></i>
        </Link>
        <Link
          to='#'
          onClick={() => setIsNotification(true)}
          className='text-white mx-2'
        >
          <i className='mdi mdi-bell-outline mdi-36px'></i>
        </Link>
        <Dropdown style={{ left: 45, top: 0, position: 'absolute', minWidth: 500 }} isOpen={isOpenNotification} toggle={() => setIsNotification(!isOpenNotification)}  >
          <DropdownMenu className='p-2'
            style={{ overflow: 'auto', maxHeight: 600 }}
          >
            <b>Notifications</b>

            {notificationData.map((notificationItem, notificationIndex) => (
              <>
                <div className='row'>
                  <div className='p-3 col-3'>
                    <img src='https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'
                      width="65" height="65" style={{ borderRadius: 60, }}
                    />
                  </div>


                  {notificationItem.data.notification_type === 'invitation' ? (
                    <div className='p-3 col-9'>
                      <b>{notificationItem.data.notification_type}</b>
                      <p >{notificationItem.data.owner}{' '}invited you in {notificationItem.data.invited_in}</p>
                      <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                    </div>
                  ) : notificationItem.data.notification_type === 'alert_notification' ?(
                  <>
                    <td>
                      <b className='mt-2'>{notificationItem.data.notification_type}</b>
                    </td>
                    <td>
                      <p> {notificationItem.data.message}</p>
                    </td>
                    <td>
                      <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                    </td>
                  </>
                  ):
                  (
                    <div className='p-3 col-9'>
                      <b>{notificationItem.data.notification_type}</b>
                      <p> {notificationItem.data.owner}{' '}assigned you task {notificationItem.data.task}</p>
                      <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                    </div>
                  )}
                </div>
                {notificationItem.data.notification_type === 'invitation' ? (
                  <div className='d-flex justify-content-center'>
                    <Button
                      className='btn btn-secondary m-4'
                      type='button'
                      color='black'
                      onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                      onMouseOut={(e) => e.target.style.backgroundColor = ''}
                      onClick={() => rejectNotification(notificationItem.id)}
                    >
                      Reject
                    </Button>
                    <Button
                      className='btn btn-primary m-4 '
                      type='button'
                      color='black'
                      onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                      onMouseOut={(e) => e.target.style.backgroundColor = ''}
                      onClick={() => acceptNotification(notificationItem.data.invitation_code, notificationItem.id)}
                    >
                      Accept
                    </Button>
                  </div>
                ) : (
                  <div className='d-flex justify-content-center'>

                    <Button
                      className='btn btn-primary m-4 '
                      type='button'
                      color='black'
                      onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                      onMouseOut={(e) => e.target.style.backgroundColor = ''}
                      onClick={() => markAsReadNotification(notificationItem.id)}
                    >
                      Mark as read
                    </Button>
                  </div>
                )}

                <hr />

              </>
            ))}
            <div className='d-flex justify-content-center'>
              <Link to={`/${username}/notifications/all`}
                onClick={() =>  setIsNotification(false)}
              >
                View All Notifications
              </Link>
            </div>

          </DropdownMenu>
        </Dropdown>

      </div>

      <div style={{ marginTop: 200 }}>
        <Link
          to='#'
          onClick={() => setIsOpenDropdown(true)}
          className='text-white mx-2'
        >
          <img src='https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'
            width="40" height="40" style={{ borderRadius: 60 }} />
        </Link>
        <Dropdown style={{ left: 50, minWidth:300,marginBottom:500 }} isOpen={isOpenDropdown} toggle={() => setIsOpenDropdown(!isOpenDropdown)}  >
          <DropdownMenu className='p-2'  >
            <DropdownItem header>Account</DropdownItem>
            <div className='d-flex justify-content-between'>

              <DropdownItem  >
                <Button
                  type='button'
                  color='black'
                  onClick={() => logOut()}
                >
                  Log out
                </Button>

              </DropdownItem>
              <DropdownItem  >
                <Link
                  to={`/${username}/profile/${loginUserID}`}
                  className='mx-2'
                >
                  My profile
                </Link>
              </DropdownItem>
              <Button
                className="btn btn-light text-primary"
                onClick={() => setIsInnerDropdown(true)}
              >
                switch account
              </Button>
              <Dropdown isOpen={isOpenDropdown} toggle={() => setIsInnerDropdown(!isInnerDropdown)}>
                <DropdownMenu>
                  {owners.map((item) => ((
                    < DropdownItem onClick={() => handleOwners(item.owner.username, item.role)}>
                      {item.owner.username}
                    </DropdownItem>
                  )))}
                 
                </DropdownMenu>
              </Dropdown>
            </div>

          </DropdownMenu>

        </Dropdown>

      </div>
      {/* <div>
        <NavDropdownMenu title="Dropdown R" id="collasible-nav-dropdown" alignRight >
          <NavDropdown.Item >
            <Button
              type='button'
              color='black'
              onClick={() => logOut()}
            >
              Log out
            </Button>
          </NavDropdown.Item>
          <NavDropdown.Item >
            <Link
              to={`/${username}/profile`}
              className='mx-2'
            >
              My profile
            </Link>
          </NavDropdown.Item>
          <DropdownSubmenu title=" switch account">
            {owners.map((item) => ((
              <NavDropdown.Item onClick={() => handleOwners(item.owner.username, item.role)} >
                {item.owner.username}
              </NavDropdown.Item>
            )))}
          </DropdownSubmenu>
        </NavDropdownMenu>
      </div> */}


      {errorModel === true ? (
        <SweetAlert
          title={errorModelText}
          timeout={1000}
          style={{
            position: "absolute",
            top: "0px",
            // right: "0px"
          }}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => setErrorModel(false)}

        ></SweetAlert>
      ) : (null)}
    </div>
  );
};
export default MinSideBar;
