import React, { useState } from 'react';
import Axios from 'axios';
import {baseURL} from  '../../../src/components/Admin/BaseURL'
// Axios.defaults.baseURL = "${baseURL}";
export const createBoardrequest = async (
    boardTitle,
    workspaceId,
    userName,
    accessToken
) => {
    const response = await fetch(
        `${baseURL}/${userName}/boards`,
        {
            body: JSON.stringify({
                title: boardTitle,
                workspace: workspaceId

            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const getBoardsAdminAndOwner = async (accessToken, userName, selectedWorkspace) => {

    try {
        const response = await Axios.get(`${baseURL}/${userName}/workspaces/${selectedWorkspace}`,
            {
                headers: {
                    Authorization: "Bearer" + "  " + accessToken,
                    Accept: "*/*",
                }
            }
        );
        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }

        return response
    } catch (err) {
        throw err;
    }
}
export const UpdateBoardRequest = async (accessToken, userName, boardID, boardName) => {
    const response = await fetch(
        `${baseURL}/${userName}/boards/${boardID}`,
        {
            body: JSON.stringify({
                title: boardName,

            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const deleteBoardRequest = async (accessToken, userName, boardID) => {
    const response = await fetch(
        `${baseURL}/${userName}/boards/${boardID}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
};
export const getMembersmailsRequest = async (userName, accessToken, boardD) => {
    const response = await fetch(
        `${baseURL}/${userName}/board-member/not-exists-users/${boardD}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },

        }
    );
    const result = await response.json();
    return result;
}
export const addUser = async (memberId, boardID, role, userName, accessToken) => {
    const response = await fetch(
        `${baseURL}/${userName}/board-member`,
        {

            body: JSON.stringify({
                member: memberId,
                board: boardID,
                role: role,
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};
export const deleteBoardUser = async (memberId, boardID,  userName, accessToken) => {
    const response = await fetch(
        `${baseURL}/${userName}/board-member/remove`,
        {

            body: JSON.stringify({
                member: memberId,
                board: boardID,
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer" + "  " + accessToken,
            },
        }
    );
    const result = await response.json();
    return result;
};