import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  inviteUser,
  getBoardWorkspaceRoles,

} from '../../../Services/AdminServices/TeamServices';
import {
  createBoardrequest,
  getBoardsAdminAndOwner

} from '../../../Services/AdminServices/BoardServices';
import {
  createWorkspacerequest,
  getWorkSpacesAdminAndOwner
} from '../../../Services/AdminServices/WorkspaceServices'
import * as action from '../../../redux/action/index';

import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Form,
  Row,
  Col,
  Spinner
} from 'reactstrap'

const SideBarItem = (props) => {
  const dispatch = useDispatch();
  const selectedBoardID = useSelector(state => state.reducer.selectedBoardID);
  const boardName = useSelector(state => state.reducer.boardName);
  const selectedWorkspaceID = useSelector(state => state.reducer.selectedWorkspaceID)
  const workspaceName = useSelector(state => state.reducer.workspaceName)
  const whichIsUpdate = useSelector(state => state.reducer.whichIsUpdate)
  const reloadWorkspaces = useSelector(state => state.reducer.reloadWorkspaces)


  const navigate = useNavigate();
  let { pathname } = useLocation();
  const [inviteModel, setInviteModel] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState("");
  const [errorModelText, setErrorModelText] = useState("");
  const [errorModel, setErrorModel] = useState(false);
  const [addWorkspace, setAddWorkspace] = useState(false);
  const [addNewBoard, setAddNewBoard] = useState('');
  const [boards, setBoards] = useState([]);
  const userData = localStorage.getItem("userData");
  const transformedData = JSON.parse(userData);
  const { accessToken, userName } = transformedData;
  const [workspaces, setWorkspaces] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [successModelText, setSuccessModelText] = useState('');
  const [successModel, setSuccessModel] = useState(false);
  const [workspaceTitle, setWorkspaceTitle] = useState('');
  const [boardTitle, setBoardTitle] = useState('');
  const [defaultWorkspace, setDefaultWorkspace] = useState('');
  const [workspaceID, setWorkspaceID] = useState('');
  const [isBoardLoader, setIsBoardLoader] = useState(false);
  const [isWorkspaceLoader, setIsWorkspaceLoader] = useState(false);
  const [userRole, setUserRole] = useState('');
  
  
  useEffect(() => {
    getWorkSpaces();
  }, [userName, reloadWorkspaces]);

  useEffect(() => {
    getBoardsWorkspacesRoles();
    // getBoards(workspaceID);
  }, [userName,]);
  useEffect(() => {
    let list = [...workspaces];
    list.map((workspace,workspaceID)=>{
        if (workspace.id == selectedWorkspaceID){
          setDefaultWorkspace(workspaceName)
          list[workspaceID].title = workspaceName
        }

     
  })
    setWorkspaces(list)
  }, [selectedWorkspaceID,workspaceName,whichIsUpdate, ]);

  useEffect(() => {
    let list = [...boards]
    list.map((board, boardIndex) => {
      if (boardName === 'deleteBoard') {

        if (selectedBoardID == board.id) {
          list.splice(boardIndex,1)
        }

      }
      else {

        if (selectedBoardID == board.id) {
          list[boardIndex].title = boardName
        }

      }
    })
    setBoards(list)
  }, [selectedBoardID, boardName]);
  const getWorkSpaces = async () => {
    try {
      setIsWorkspaceLoader(true)
      const result = await getWorkSpacesAdminAndOwner(accessToken, userName);
      if (result.status === 200) {
        setUserRole(result.data.selected_workspace_role)
        setWorkspaces(result.data.workspaces);
        if (result.data.selected === null) {

          setWorkspaceID(result.data.workspaces[0].id);
          setDefaultWorkspace(result.data.workspaces[0].title);
          setSelectedWorkspace(result.data.workspaces[0].title);
          props.SelectionTrigger('workspace');
          props.selectedWorkspace({ 'label': result.data.workspaces[0].title, 'value': result.data.workspaces[0].id, 'description': result.data.workspaces[0].description, 'userRole': result.data.selected_workspace_role })
          getBoards(result.data.workspaces[0].id);
        }
        else {

          setWorkspaceID(result.data.selected.id)
          setDefaultWorkspace(result.data.selected.title);
          setSelectedWorkspace(result.data.selected.title);
          props.selectedWorkspace({ 'label': result.data.selected.title, 'value': result.data.selected.id, 'description': result.data.selected.description, 'userRole': result.data.selected_workspace_role })
          getBoards(result.data.selected.id);
        }

        setIsWorkspaceLoader(false)

      }
    } catch (err) {
      console.log('workspace error', err)
      // setErrorModelText(err.response.data.message)
      // setErrorModel(true);
    }
  }
  const getBoards = async (workspaceId) => {
    try {
      setIsBoardLoader(true)
      const result = await getBoardsAdminAndOwner(accessToken, userName, workspaceId);
      if (result.status === 200) {
        if (result.data.workspace.boards) {
          setBoards(result.data.workspace.boards);
          dispatch(action.boardID(result.data.workspace.boards[0].id));
        }

        else {
          setBoards(result.data.workspace.allowed_boards);
          dispatch(action.boardID(result.data.workspace.allowed_boards[0].id));

        }

        setIsBoardLoader(false);
      }
    } catch (err) {
      console.log('error', err)
      // setErrorModelText(err.response.data.message)
      // setErrorModel(true);
    }
  }
  const getBoardsWorkspacesRoles = async () => {
    try {
      const result = await getBoardWorkspaceRoles();
      if (result.status === 200) {

        setRoles(result.data);
        // console.log('pakistan', roles)

      }
    } catch (err) {
      setErrorModelText(err.response.data.message)
      setErrorModel(true);
    }
  }
  const roleOption = roles.map((item) => ({
    label: item.name, value: item.id
  }));
  const workspaceOptions = workspaces.map((item) => ({
    label: item.title, value: item.id,
    description: item.description,
    userRole: userRole

  }));
  const handleTextes = (event) => {
    switch (event.target.name) {
      case 'InviteEmail':
        setInviteEmail(event.target.value)
        break;
      case 'Workspace':
        setWorkspaceTitle(event.target.value)
        break;
      case 'Board':
        setBoardTitle(event.target.value)
        break;


    }
  }
  const handleSelectedRole = async (role) => {
    await setSelectedRole(role)
  }
  const handleChange = async (e) => {

    await setSelectedWorkspace(e);
    props.selectedWorkspace(e)
    getBoards(e.value);
    setWorkspaceID(e.value)
    localStorage.setItem(
      "selected_workspace",
      e.value
    );
    props.SelectionTrigger('workspace');
    navigate(`/${userName}/workspaces/${e.value}`)

  }
  const CreateWorkspaceNow = async () => {
    setAddWorkspace(!addWorkspace)
    if (
      typeof workspaceTitle !== 'undefined' &&
      workspaceTitle !== ''

    ) {
      const result = await createWorkspacerequest(
        workspaceTitle,
        userName,
        accessToken
      );
      if (result.status == true) {
        navigate(`/${userName}/workspaces/${result.workspace.id}`)
        localStorage.setItem(
          "selected_workspace",
          result.workspace.id
        );
        props.selectedWorkspace({ 'label': result.workspace.title, 'value': result.workspace.id })
        getBoards(result.workspace.id);
        setWorkspaceID(result.workspace.id)

        setWorkspaceTitle('');
        setSuccessModelText(result.message)
        setSuccessModel(true);
        getWorkSpaces();
      }
      else {

        setErrorModelText(result.message)
        setErrorModel(true);
      }

    } else {
      setErrorModelText('Please fill required field')
      setErrorModel(true);
    }
  }
  const CreateBoardNow = async () => {
    setAddNewBoard(!addNewBoard)
    if (
      typeof boardTitle !== 'undefined' &&
      boardTitle !== ''

    ) {
      const result = await createBoardrequest(
        boardTitle,
        workspaceID,
        userName,
        accessToken
      );
      if (result.status == true) {
        navigate(`/${userName}/boards/${result.board.id}`)
        setBoardTitle('');
        setSuccessModelText(result.message)
        setSuccessModel(true);
        getBoards(workspaceID)

      }
      else {

        setErrorModelText(result.message)
        setErrorModel(true);
      }

    } else {
      setErrorModelText('Please fill required field')
      setErrorModel(true);
    }
  }

  return (
    <div  >
      {/* {isWorkspaceLoader === false ? (
       <> */}
      <div>
        <Select
          placeholder={defaultWorkspace}
          value={selectedWorkspace}
          options={workspaceOptions}
          onChange={handleChange}
        />
      </div>

      {userRole !== 'viewer' ? (
        <>
          <div className='m-2'>
            <Button
              type='submit'
              color='white'

              onClick={() => {
                setAddWorkspace(true)
              }}
            >
              <span className='px-2'>
                <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                  <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                  </path>
                </svg>
              </span>
              Add New Workspace
            </Button>
          </div>
          <div className='m-2'>
            <Button
              type='submit'
              color='white'

              onClick={() => {
                setAddNewBoard(true)
              }}

            >
              <span className='px-2'>
                <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                  <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                  </path>
                </svg>
              </span>

              Add New Board
            </Button>
          </div>

        </>
      ) : (null)}
      <hr />
      <b> Boards</b>
      <div>
        {isBoardLoader === false ? (
          boards.map((item, index) => (
            <div key={index} className="m-3">
              <Link
                to={`/${userName}/boards/${item.id}`}
              >
                {item.title}
              </Link>
            </div>
          ))
        ) : (
          <div className='d-flex justify-content-center'>
            <Spinner animation="border" variant="primary" />
          </div>
        )
        }

      </div>

      <Modal
        size='md'
        isOpen={addWorkspace}
        centered={true}
        toggle={() =>
          setAddWorkspace(!addWorkspace)
        }
      >
        <ModalHeader
          toggle={() =>
            setAddWorkspace(!addWorkspace)
          }
        >
          Create Workspace
        </ModalHeader>
        <ModalBody>
          <div>

            <Label className='my-2'>Workspace name</Label>

            <Input
              className='my-2'
              id="exampleWorkspace"
              name="Workspace"
              placeholder="Enter New Workspace"
              type="text"
              value={workspaceTitle}
              onChange={handleTextes}
            />

          </div>
        </ModalBody>
        <ModalFooter>
          <div className='text-end'>
            <Button
              color='secondary'
              onClick={() => {
                setAddWorkspace(!addWorkspace)
              }}
            >
              Cancel
            </Button>
          </div>
          <div className='text-end'>
            <Button
              type='submit'
              color='primary'
              onClick={CreateWorkspaceNow}
            >
              Create Workspace
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        size='md'
        isOpen={addNewBoard}
        centered={true}
        toggle={() =>
          setAddNewBoard(!addNewBoard)
        }
      >
        <ModalHeader
          toggle={() =>
            setAddNewBoard(!addNewBoard)
          }
        >
          Create New Board
        </ModalHeader>
        <ModalBody>
          <div >
            <Label className='my-2'>Enter board name</Label>
            <Input
              className='my-2'
              id="board"
              name="Board"
              placeholder="New Board"
              type="text"
              value={boardTitle}
              onChange={handleTextes}
            />

          </div>
        </ModalBody>
        <ModalFooter>
          <div className='text-end'>
            <Button
              color='secondary'
              onClick={() => {
                setAddNewBoard(!addNewBoard)
              }}
            >
              Cancel
            </Button>
          </div>
          <div className='text-end'>
            <Button
              type='submit'
              color='primary'
              onClick={CreateBoardNow}

            >
              Create Board
            </Button>
          </div>
        </ModalFooter>
      </Modal>



      {errorModel === true ? (
        <SweetAlert
          title={errorModelText}
          timeout={1000}
          style={{
            position: "absolute",
            top: "0px",
            // right: "0px"
          }}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => setErrorModel(false)}

        ></SweetAlert>
      ) : (null)}
      {successModel === true ? (
        <SweetAlert
          title={successModelText}
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            // right: "0px"
          }}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={() => setSuccessModel(false)}

        ></SweetAlert>
      ) : (null)}
    </div>
  );
};
export default SideBarItem;
