import {
  AUTHENTICATE,
  LOGOUT,
  SET_DID_TRY_AL,
  REGISTER_TOKEN,
  RELOAD_WORKSPACE,
  UPDATE_BOARD,
  UPDATE_TEAM,
  SELECTED_NOTIFICATION,
  BOARDID,
  TEAMID,
  UPDATE_WORKSPACE,
  RELOAD_TEAM

} from "../action";

const initialState = {
  // role: null,
  registerPageToken: null,
  userName: null,
  Authorization: null,
  userId: null,
  didTryAutoLogin: false,
  reloadWorkspaces: '',
  isReloadTeams: '',
  notificationItem: '',
  boardID: '',
  selectedBoardID: '',
  boardName: '',
  selectedWorkspaceID: '',
  workspaceName: '',
  whichIsUpdate: '',
  teamID: '',
  teamname: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RELOAD_WORKSPACE:
      return {
        reloadWorkspaces: action.reloadWorkspaces,
      };
    case REGISTER_TOKEN:
      return {
        token: action.token,
      };
    case AUTHENTICATE:
      return {
        userName: action.userName,
      };
    case SET_DID_TRY_AL:
      return {
        ...state,
        didTryAutoLogin: true,
      };
    case LOGOUT:
      return {
        ...initialState,
        didTryAutoLogin: true,
      };
    case UPDATE_WORKSPACE:
      return {
        ...initialState,
        selectedWorkspaceID: action.selectedWorkspaceID,
        workspaceName: action.workspaceName,
        whichIsUpdate: action.whichIsUpdate,

      };
    case UPDATE_BOARD:
      return {
        ...initialState,
        selectedBoardID: action.selectedBoardID,
        boardName: action.boardName,
      };
    case UPDATE_TEAM:
      return {
        ...initialState,
        teamID: action.teamID, teamname: action.teamname

      };
    case SELECTED_NOTIFICATION:
      return {
        ...initialState,
        notificationItem: action.notificationItem,
      };
    case BOARDID:
      return {
        ...initialState,
        boardID: action.boardID,
      };
    case TEAMID:
      return {
        ...initialState,
        teamReloadID: action.teamReloadID,
      };
    case RELOAD_TEAM:
      return {
        ...initialState,
        isReloadTeams: action.isReloadTeams,
      };
      
      

    default:
      return state;
  }
};
