// import AsyncStorage from "@react-native-async-storage/async-storage";

export const AUTHENTICATE = "AUTHENTICATE";
export const LOGOUT = "LOGOUT";
export const SET_DID_TRY_AL = "SET_DID_TRY_AL";
export const REGISTER_TOKEN = "REGISTER_TOKEN";

export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const UPDATE_BOARD = "UPDATE_BOARD";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const SELECTED_NOTIFICATION = "SELECTED_NOTIFICATION";
export const BOARDID = "BOARDID";
export const TEAMID = "TEAMID";
export const RELOAD_WORKSPACE = "RELOAD_WORKSPACE";
export const RELOAD_TEAM = "RELOAD_TEAM";

export const authenticate = (userName) => {
  return { type: AUTHENTICATE, userName: userName };
};
export const registerToken = ( token) => {
  return { type: REGISTER_TOKEN, token: token };
};

export const logout = () => {
  // AsyncStorage.removeItem("userData");
  return { type: LOGOUT };
};
export const setDidTryAL = () => {
  return { type: SET_DID_TRY_AL };
};

export const updateWorkspaceLocally = (selectedWorkspaceID, workspaceName,whichIsUpdate) => {
  return { type: UPDATE_WORKSPACE, selectedWorkspaceID: selectedWorkspaceID, workspaceName: workspaceName, whichIsUpdate: whichIsUpdate };
};
export const updateBoardLocally = (selectedBoardID,boardName) => {
  return { type: UPDATE_BOARD, selectedBoardID: selectedBoardID, boardName: boardName };
};
export const updateTeamLocally = (ID,teamname) => {
  return { type: UPDATE_TEAM, teamID: ID, teamname: teamname };
};
export const selectedNotificationData = (notificationItem) => {
  return { type: SELECTED_NOTIFICATION, notificationItem: notificationItem };
};
export const boardID = (ID) => {
  return { type: BOARDID, boardID: ID };
};
export const teamID = (ID) => {
  return { type: TEAMID, teamReloadID: ID };
};
export const reloadWorkspaces = (ID) => {
  return { type: RELOAD_WORKSPACE, reloadWorkspaces: ID };
};
export const reloadTeams = (isReloadTeams) => {
  return { type: RELOAD_TEAM, isReloadTeams: isReloadTeams };
};


// export const saveDataToStorage = (token) => {
//   AsyncStorage.setItem(
//     "userData",
//     JSON.stringify({
//       token: token,
//     })
//   );
// };