import React, { useEffect, useState, } from "react";
import 'react-calendar/dist/Calendar.css';
import SideBarItem from "./SideBars/SideBarItem";
import MinSideBar from "./SideBars/MinSideBar";
import "./styles.css";

// import { useHistory } from "react-router-dom";
import { useLocation, Outlet, useParams, useNavigate } from 'react-router-dom'
const Workspace = ( props ) => {
    let {pathname} = useLocation();
    useEffect(()=>{
        localStorage.setItem(
            "workspace_path",
            pathname
        );
    }, [pathname]);
    let { username } = useParams();
    const navigate = useNavigate();
    const [selectedWorkspace, setSelectedWorkspace] = useState('')
    const [viewTrigger, setViewTrigger] = useState('')
    const [selectionTrigger, setSelectionTrigger] = useState()
    const [selectedBoard, setSelectedBoard] = useState('');
   
    const getBoardName = async (name) => {
        console.log('hgsahgashghagcvgc', name)
        await setSelectedBoard(name);
    }
    const getSelectionTrigger = (name) => {
        setSelectionTrigger(name)
        setViewTrigger('')

    }
    const getSelectedWorkspaceDetail = (workspaceDetail) => {
        setSelectedWorkspace(workspaceDetail)
        let workspace_path = localStorage.getItem('workspace_path');
        if (workspace_path && workspace_path !== `/${username}/workspaces` && workspace_path !== `/${username}`) {
            navigate(workspace_path);
        }
        else {
            localStorage.setItem(
                "selected_workspace",
                workspaceDetail.value
            );
            navigate(`/${username}/workspaces/${workspaceDetail.value}`);
            
        }
    }
    return (

        <div className="row">
            <div className=" col-3 d-flex position-fixed mt-0 p-0 min-vh-100">
                <div className="bg-dark col-2  m-0  ">
                    <MinSideBar  />
                </div>
                    <div className="sideBar col-10 m-0 overflow-auto ">
                        <SideBarItem
                            // BoardName={getBoardName}
                            SelectionTrigger={getSelectionTrigger}
                            selectedWorkspace={getSelectedWorkspaceDetail}
                            
                        />

                    </div>
            </div>
            <Outlet/>
            
          
            
        </div>
    );
};

export default Workspace;
