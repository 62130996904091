import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    Form,
    Input,
    Col,
    Row,
    Container,
    Label, Spinner
} from 'reactstrap'
import { 
    getProfile, 
    UpdateProfileRequest,
    UpdateProfileImageRequest
 } from '../../../Services/AdminServices/ProfileServices';
import MinSideBar from '../SideBars/MinSideBar';
import { useParams } from "react-router-dom";
const Profile = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [profileData, setProfileData] = useState([]);
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, loginUserID } = transformedData;
    const [isShowProfile, setIsShowProfile] = useState(false)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mail, setMail] = useState('');
    const [altMail, setAltMail] = useState('');
    const [userName, setUserName] = useState('')
    const [imageLink, setImageLink] = useState('https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png')
    const [profileID, setProfileID] = useState('');
    const [isDisableField, setIsDisableField] = useState(false);

    useEffect(() => {
        getProfileData();
        
    }, [id]);
    useEffect(() => {
        if (profileID === loginUserID ){
            setIsDisableField(false)
        }
        else{
            setIsDisableField(true)
        }

    }, [profileID, loginUserID]);
    const getProfileData = async () => {
        try {
            setIsShowProfile(true)
            const result = await getProfile(accessToken, id);
            if (result.status === true) {
                setIsShowProfile(false);
                setProfileID(result.profile.id)
                setFirstName(result.profile.first_name);
                setLastName(result.profile.last_name);
                setMail(result.profile.email);
                setAltMail(result.profile.alt_email);
                setUserName(result.profile.username);
                setImageLink(result.profile.profile_image.media.profile.url)


            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const UpdateProfileNow = async () => {
        if (
            typeof firstName !== 'undefined' &&
            firstName !== '' &&
            typeof lastName !== 'undefined' &&
            lastName !== '' &&
            typeof mail !== 'undefined' &&
            mail !== '' &&
            typeof altMail !== 'undefined' &&
            altMail !== '' &&
            typeof userName !== 'undefined' &&
            userName !== ''
        ) {
           
                try {
                    const result = await UpdateProfileRequest(accessToken, firstName, lastName, mail, altMail, userName);
                    // console.log('changePasswordRequest', result)
                    if (result.status === true) {
                        console.log('update Profile', result.profile)
                    }
                } catch (err) {
                    setErrorModelText(err)
                    setErrorModel(true);
                }
          

        }
        else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    const UpdateProfileImage = async (img) => {
     
        const formData = new FormData();
        formData.append("profile_image", img);
            try {
                const result = await UpdateProfileImageRequest(accessToken, formData);
                
                if (result.status === true) {
                    getProfileData()
                    console.log('update result', result)
                }
            } catch (err) {
                setErrorModelText(err)
                setErrorModel(true);
            }

    }
    return (
        <React.Fragment>
                <div className='row'>

               
                <div className=" col-3 d-flex position-fixed mt-0 p-0 min-vh-100">
                    <div className="bg-dark col-2  m-0  ">
                        <MinSideBar  />
                    </div>
              
                </div>
                {isShowProfile === false ?(
                <Form>
                    <Row className='my-5 d-flex justify-content-center '>
                        <Col xs={5}>
                            <div className=' d-flex justify-content-center mb-4 ' >
                               <label style={{width:100,height:100, position:'relative', }}>

                                    <input className='position-absolute' style={{ top: 30, padding:50, opacity: 0 }} 
                                        title="" type="file" accept="image/*" onChange={(event) => UpdateProfileImage(event.target.files[0])} />
                                <Link
                                    to='#'
                                    className='text-white mx-2'
                                >
                                    <img src={imageLink}
                                        width="100" height="100" style={{ borderRadius: 60 }}
                                        />
                                    
                                </Link>
                                </label>
                            </div>
                            <div className='d-flex justify-content-center' >
                                <div className='col-3 mt-3'>
                                    <b >First Name: </b>
                                </div>
                                <div className='col-8'>
                                    <Input
                                        value={firstName}
                                        className='my-3'
                                        id="examplecode"
                                        name="First_Name"
                                        placeholder="First Name"
                                        type="text"
                                        disabled={isDisableField}
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onBlur={() => UpdateProfileNow()}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='col-3 mt-3'>
                                    <b >Last Name: </b>
                                </div>
                                <div className='col-8'>
                                    <Input
                                        value={lastName}
                                        className='my-3'
                                        id="examplePassword"
                                        name="Last-Name"
                                        placeholder="Last Name"
                                        type="text"
                                        disabled={isDisableField}
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onBlur={() => UpdateProfileNow()}
                                    />
                                </div>

                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='col-3 mt-3'>
                                    <b >Email: </b>
                                </div>
                                <div className='col-8'>
                                    <Input
                                        disabled={true}
                                        value={mail}
                                        className='my-3'
                                        id="exampleConfirm"
                                        name="Email"
                                        placeholder="Email"
                                        type="email"
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                        onChange={(e) => setMail(e.target.value)}
                                        
                                    />
                                </div>

                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='col-3 mt-3'>
                                    <b >Alt Email: </b>
                                </div>
                                <div className='col-8'>
                                    <Input
                                        value={altMail}
                                        className='my-3'
                                        id="exampleConfirm"
                                        name="Alt Email"
                                        placeholder="Alt Email"
                                        type="email"
                                        disabled={isDisableField}
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                        onChange={(e) => setAltMail(e.target.value)}
                                        onBlur={() => UpdateProfileNow()}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>

                                <div className='col-3 mt-3'>
                                    <b >Username: </b>
                                </div>
                                <div className='col-8'>
                                    <Input
                                        disabled={true}
                                        value={userName}
                                        className='my-3'
                                        id="examplePassword"
                                        name="User-Name"
                                        placeholder="username"
                                        type="text"
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#DCDCDC'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = ''}
                                        onChange={(e) => setUserName(e.target.value)}
                                        // onBlur={() => UpdateProfileNow()}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Form>
                ) : (
                        <div className='d-flex justify-content-center'>

                            <Spinner animation="border" variant="primary" />
                        </div>
                )}
                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}
                </div>
        </React.Fragment>


    );
}

export default Profile;