import React, { useState } from 'react';
import Axios from 'axios';
import { baseURL } from '../../src/components/Admin/BaseURL';
export const adminRegisterUser = (
    Firstname,
    Lastname,
    Email,
    Altemail,
    Password,
    Username) => {

    const result = Axios.post(`${baseURL}/register`, {
        email: Email,
        password: Password,
        username: Username,
        first_name: Firstname,
        last_name: Lastname,
        alt_email: Altemail,

    }).then((response) => {
        if (response.status === 200) {
            return true;

        } else {
            return false;
        }
    }).catch((err) => {
        const error = err.response.data.errors;
        if (error.username && error.email) {
            return "Email and Username has already been taken";
        } else if (error.username) {
            return error.username[0];
        } else if (error.email) {
            return error.email[0];
        }
    });
    return result;
}
export const userAthentication = async (code) => {
    try {
        const response = await fetch(
            `${baseURL}/invite/${code}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const registerInvitedUser = async (
    Firstname,
    Lastname,
    Password,
    Username,
    key
    ) => {
    try {
        const response = await fetch(
            `${baseURL}/invite/${key}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(
                    {
                        password: Password,
                        username: Username,
                        first_name: Firstname,
                        last_name: Lastname,
                    }),
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const loginUser = async (Email, Password) => {

    try {
        const response = await Axios.post(`${baseURL}/login`, {
            email: Email,
            password: Password
        });

        if (response.status !== 200) {
            throw new Error("Something went wrong!");
        }
        const data = response;
        return data
    } catch (err) {
        throw err;
    }
}
export const ForgotPasswordRequest = async (email) => {
  try {
      const result = await Axios.post(`${baseURL}/forgot-password`, {
      email: email,

      headers: {
        "content-type": "application/json",
      },
    });
    
      return result;
  } catch (err) {
    const error = err.response.data.message;
    throw error;
  }
};

export const changePasswordRequest = async (email, code, password,confirmPassword) => {
  try {
      const result = await Axios.post(`${baseURL}/reset-password`, {
      token:code,
      password:password,
      password_confirmation:confirmPassword,
      email: email,
      headers: {
        "content-type": "application/json",
      },
    });
    

      return result;
  } catch (err) {
    const error = err.response.data.message;
    throw error;
  }

};

export const logOutUser = async (accessToken) => {
    try {
        const response = await fetch(
            `${baseURL}/logout`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const getOwnersRequest = async (accessToken) => {
    try {
     
        const response = await fetch(
            `${baseURL}/owners`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
