import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    createTeamrequest,
    getAllTeams,
  
} from '../../../Services/AdminServices/TeamServices';
import * as action from '../../../redux/action/index';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Label,
    Form,
    Row,
    Col,
    Spinner
} from 'reactstrap'
const TeamSidebar = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [teams, setTeams] = useState([]);
    const teamID = useSelector(state => state.reducer.teamID);
    const teamname = useSelector(state => state.reducer.teamname);
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, userName } = transformedData;
    const [errorModelText, setErrorModelText] = useState("");
    const [errorModel, setErrorModel] = useState(false);
    const [createTeam, setCreateTeam] = useState(false);
    const [teamTitle, setTeamTitle] = useState('');
    const [successModelText, setSuccessModelText] = useState('');
    const [successModel, setSuccessModel] = useState(false);
    const [noEveryOne, setNoEveryOne] = useState('');
    const [loader, setLoader] = useState(false);
    const isReloadTeams = useSelector(state => state.reducer.isReloadTeams);

       
    useEffect(() => {
        getTeams();
    }, [userName, teamID, teamname, isReloadTeams  ]);
    
    const getTeams = async () => {
        setLoader(true)
        try {
            const result = await getAllTeams(accessToken, userName);
            if (result.status === 200) {
                dispatch(action.teamID(result.data.teams[0].id));
                setTeams(result.data.teams);
                setLoader(false)


            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const handleTextes = (event) => {
        switch (event.target.name) {
            case 'teamTitle':
                setTeamTitle(event.target.value)
                break;

        }
    }
    const CreateTeamNow = async () => {
        setCreateTeam(!createTeam)
        if (
            typeof teamTitle !== 'undefined' &&
            teamTitle !== ''

        ) {
            const result = await createTeamrequest(
                teamTitle,
                userName,
                accessToken
            );
            if (result.status == true) {
                props.TeamName(result.team)
                props.WhichShowComponent('main')
                navigate(`/${userName}/team/${result.team.id}/all`)
                getTeams();
                setTeamTitle('');
                setSuccessModelText(result.message)
                setSuccessModel(true);

            }
            else {

                setErrorModelText(result.message)
                setErrorModel(true);
            }

        } else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }
    // useEffect(() => {
      
    //   let list = [...teams]
    //     list.map((team, teamIndex) => {
    //         if (teamID == team.id) {
               
    //             list[teamIndex].title = teamname
    //         }
    //     })
    //     setTeams(list)
    // }, [teamID, teamname, ]);
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center" >
                <h5 className='p-3' >Teams</h5>
                <Button
                    type='submit'
                    color='white'
                    onClick={() => { setCreateTeam(true) }}
                >
                    <span className='px-2'>
                        <svg viewBox="0 0 20 20" fill="currentColor" width="19" height="19" aria-hidden="true" class="icon_component icon_component--no-focus-style">
                            <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H9.25V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H10.75V3Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                            </path>
                        </svg>
                    </span>
                    New
                </Button>
            </div>
            <div >
                <Link
                    to={`/${userName}/teams/everyone`}

                >
                    Everyone at {userName} /{noEveryOne}
                </Link>

            </div>

            <hr />
            <div>
                {loader === false ? (
                    teams.map((item, index) => (
                        <div key={index} className="m-4" >
                            <Link
                                to={`/${userName}/team/${item.id}/all`}

                            >
                                {item.title}
                            </Link>
                        </div>
                    ))
                ) : (
                    <div className='d-flex justify-content-center'>

                        <Spinner animation="border" variant="primary" />
                    </div>
                )}

            </div>
            {errorModel === true ? (
                <SweetAlert
                    title={errorModelText}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        // right: "0px"
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    error
                    onConfirm={() => setErrorModel(false)}

                ></SweetAlert>
            ) : (null)}
            <Modal
                size='md'
                isOpen={createTeam}
                centered={true}
                toggle={() => setCreateTeam(!createTeam)}
            >
                <ModalHeader
                    toggle={() => setCreateTeam(!createTeam)}
                >
                    Create New Team
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col>
                                <div className='mb-3'>
                                    <Input
                                        name='teamTitle'
                                        type="text"
                                        id='teamTitle'
                                        placeholder='Enter team name'
                                        value={teamTitle}
                                        onChange={handleTextes}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div className='text-end'>
                        <Button
                            color='secondary'
                            onClick={() => setCreateTeam(!createTeam)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className='text-end'>
                        <Button
                            type='button'
                            color='primary'
                            onClick={CreateTeamNow}
                        >
                            Create New Team
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
            {successModel === true ? (
                <SweetAlert
                    title={successModelText}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        // right: "0px"
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    success
                    onConfirm={() => setSuccessModel(false)}

                ></SweetAlert>
            ) : (null)}
        </div>
    );
};
export default TeamSidebar;
