import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { format } from "date-fns";
import * as action from '../../../redux/action';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    getAllNotificationRequest,
    getReadNotificationsRequest,
    getUnReadNotificationsRequest
} from '../../../Services/AdminServices/NotificationServices'
import {
    Button,
    Container,
    Spinner
} from 'reactstrap'
import MinSideBar from '../SideBars/MinSideBar';
const Notifications = () => {
    const dispatch= useDispatch()
    let { username, slug } = useParams();
    const navigate = useNavigate();
    const [allNotification, setAllNotification] = useState([]);
    const [readNotification, setReadNotification] = useState([]);
    const [unreadNotification, setUnreadNotification] = useState([]);
    const userData = localStorage.getItem("userData");
    const transformedData = JSON.parse(userData);
    const { accessToken, userName } = transformedData;
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    const [isShowNotification, setIsShowNotification] = useState(false);
    const [isShowUnreadNotification, setIsShowUnreadNotification] = useState(false);
    const [isShowReadNotification, setIsShowReadNotification] = useState(false);

    useEffect(() => {
        if (slug=== 'all'){
            getNotifications();

        }
        else if (slug === 'read'){
            getReadNotifications()

        }
        else{
            getUnReadNotifications();

        }
    }, [slug]);
    const getNotifications = async () => {
        setIsShowNotification(true)
        try {
            const result = await getAllNotificationRequest(accessToken, username);

            if (result.status === true) {
                setAllNotification(result.notifications)
                setIsShowNotification(false)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
   
    const getReadNotifications = async () => {


        setIsShowReadNotification(true)
        try {
            const result = await getReadNotificationsRequest(accessToken, username);

            console.log('getReadNotifications', result)

            if (result.status === true) {
                setReadNotification(result.notifications)
                setIsShowReadNotification(false)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const getUnReadNotifications = async () => {
        setIsShowUnreadNotification(true)
        try {
            const result = await getUnReadNotificationsRequest(accessToken, username);
            console.log('getUnReadNotifications', result)
            if (result.status === true) {
                setUnreadNotification(result.notifications)
                setIsShowUnreadNotification(false)
            }
        } catch (err) {
            setErrorModelText(err.response.data.message)
            setErrorModel(true);
        }
    }
    const timeFormate = (time) => {
        var date = new Date(time);

        var formattedDate = format(date, "MMMM do, yyyy H:mma");

        return formattedDate;
    }
   
    const onMouseOver = (e) => {
      
            return e.target.style.backgroundColor = '#DCDCDC'

    }
    const onMouseOut = (e) => {
       
            return e.target.style.backgroundColor = ''
        
    }
    return (
        <>
            <div className='row'>
                <div className=" col-3 d-flex position-fixed mt-0 p-0 min-vh-100">
                    <div className="bg-dark col-2  m-0  ">
                        <MinSideBar />
                    </div>

                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <b>
                        Notifications
                    </b>
                </div>

                <div className='d-flex justify-content-center mt-3'>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            type="button"
                            onClick={() => {
                               
                                navigate(`/${username}/notifications/all`)
                            }}
                        >
                            All Notifications
                        </button>
                    </div>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            type="button"
                            onClick={() => {
                               
                                navigate(`/${username}/notifications/unread`)

                            }}
                        >
                            Unread Notifications
                        </button>
                    </div>
                    <div className='m-4'>
                        <button
                            className="btn btn-light text-primary"
                            type="button"
                            onClick={() => {
                               
                             

                                navigate(`/${username}/notifications/read`)
                            }}
                        >
                            Read Notifications
                        </button>
                    </div>
                </div>
                <div className='d-flex justify-content-center m-5 '>
                    <table className=" w-100 mt-4" >
                        <thead>
                            <th > Profile Image</th>
                            <th > Notification Type</th>
                            <th> Description</th>
                            <th> Date/Time</th>

                        </thead>
                        <tbody>
                          
                            <tr className='border-bottom border-secondary '>


                            </tr>
                            {isShowNotification !== true ? (
                                slug === 'all' &&
                                allNotification.map((notificationItem, notificationIndex) => (
                                    <tr className='border-bottom border-secondary'
                                        style={{ background: notificationItem.read_at === null ? '#d7f5ff4f' : '', cursor: 'pointer' }}
                                            onClick={() => {
                                                navigate(`/${username}/notification/${notificationItem.id}`)
                                        } }
                                    
                                    >
                                        <td className='py-3' >
                                            <img src='https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'
                                                width="65" height="65" style={{ borderRadius: 60, }}
                                            />
                                        </td>
                                        {notificationItem.data.notification_type === 'invitation' ? (
                                            <>
                                                <td>
                                                    <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                                </td>
                                                <td>
                                                    <p> {notificationItem.data.owner}{' '}invited you in {notificationItem.data.invited_in}</p>
                                                </td>
                                                <td>
                                                    <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                </td>
                                            </>
                                            ) : notificationItem.data.notification_type === 'alert_notification' ?(
                                                    <>
                                                        <td>
                                                            <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                                        </td>
                                                        <td>
                                                            <p> {notificationItem.data.message}</p>
                                                        </td>
                                                        <td>
                                                            <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                        </td>
                                                    </>
                                            ):(
                                            <>
                                                   <td>
                                                        <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                               </td>
                                               <td>
                                                        <p> {notificationItem.data.owner}{' '}assigned you task {notificationItem.data.task}</p>
                                               </td>
                                                <td>
                                                    <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                </td>
                                            </>
                                        )}



                                    </tr>


                                ))
                            ) : (
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation="border" variant="primary" />
                                </div>

                            )}


                            {isShowUnreadNotification !== true ? (
                                slug === 'unread' &&
                                unreadNotification.map((notificationItem, notificationIndex) => (

                                    <tr className='border-bottom border-secondary'
                                        style={{ background: notificationItem.read_at === null ? '#d7f5ff4f' : '', cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate(`/${username}/notification/${notificationItem.id}`)
                                            dispatch(action.selectedNotificationData(notificationItem))
                                        }}
                                    >
                                        <td className='py-3' >
                                            <img src='https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'
                                                width="65" height="65" style={{ borderRadius: 60, }}
                                            />
                                        </td>
                                        {notificationItem.data.notification_type === 'invitation' ? (
                                            <>
                                                <td>
                                                    <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                                </td>
                                                <td>
                                                    <p> {notificationItem.data.owner}{' '}invited you in {notificationItem.data.invited_in}</p>
                                                </td>
                                                <td>
                                                    <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>
                                                    <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                                </td>
                                                <td>
                                                    <p> {notificationItem.data.owner}{' '}assigned you task {notificationItem.data.task}</p>
                                                </td>
                                                <td>
                                                    <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                </td>
                                            </>)}
                                            
                                        

                                    </tr>
                                ))
                            ) : (
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation="border" variant="primary" />
                                </div>

                            )}
                            {isShowReadNotification !== true ? (
                                slug === 'read' &&
                                readNotification.map((notificationItem, notificationIndex) => (
                                    <tr className='border-bottom border-secondary'
                                        style={{ background: notificationItem.read_at === null ? '#d7f5ff4f' : '', cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate(`/${username}/notification/${notificationItem.id}`)
                                            dispatch(action.selectedNotificationData(notificationItem))
                                        }}
                                    >
                                        <td className='py-3' >
                                            <img src='https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'
                                                width="65" height="65" style={{ borderRadius: 60, }}
                                            />
                                        </td>
                                        {notificationItem.data.notification_type === 'invitation' ? (
                                            <>
                                                <td>
                                                    <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                                </td>
                                                <td>
                                                    <p> {notificationItem.data.owner}{' '}invited you in {notificationItem.data.invited_in}</p>
                                                </td>
                                                <td>
                                                    <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>
                                                    <b className='mt-2'>{notificationItem.data.notification_type}</b>
                                                </td>
                                                <td>
                                                    <p> {notificationItem.data.owner}{' '}assigned you task {notificationItem.data.task}</p>
                                                </td>
                                                <td>
                                                    <p className='mt-2'>{notificationItem.created_at ? timeFormate(notificationItem.created_at) : ''}</p>
                                                </td>
                                            </>)}

                                    </tr>
                                ))
                            ) : (
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation="border" variant="primary" />
                                </div>

                            )}


                        </tbody>

                    </table>
                </div>
            </div>


            {errorModel === true ? (
                <SweetAlert
                    title={errorModelText}
                    timeout={2000}
                    style={{
                        position: "absolute",
                        top: "0px",
                        // right: "0px"
                    }}
                    showCloseButton={false}
                    showConfirm={false}
                    error
                    onConfirm={() => setErrorModel(false)}

                ></SweetAlert>
            ) : (null)}
        </ >


    );
}

export default Notifications;