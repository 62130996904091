import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation,  } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { changePasswordRequest } from '../Services/AuthServices';
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container
} from 'reactstrap'
const ChangePassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userMail, setUserMail] = useState(location.state.userMail)
    const [token, setToken] = useState('')
    const [password, setPassword] = useState('')
    const [confirmpassword, seConfirmPassword] = useState('')
    const [errorModel, setErrorModel] = useState(false);
    const [errorModelText, setErrorModelText] = useState('');
    const changePasswordNow = async () => {
        if (
            typeof userMail !== 'undefined' &&
            userMail !== '' &&
            typeof token !== 'undefined' &&
            token !== '' &&
            typeof password !== 'undefined' &&
            password !== '' &&
            typeof confirmpassword !== 'undefined' &&
            confirmpassword !== ''
        ) {
            if (password === confirmpassword){
                try {
                    const result = await changePasswordRequest(userMail, token, password, confirmpassword);
                    console.log('changePasswordRequest', result)
                    if (result.status === 200) {
                       navigate("/")
                        setUserMail('');
                        setToken('');
                        setPassword('');
                        seConfirmPassword('');
                        


                    }
                } catch (err) {
                    setErrorModelText(err)
                    setErrorModel(true);
                }
            }
            else{
                setErrorModelText('Your password fields data not same')
                setErrorModel(true);

            }
            
        }
        else {
            setErrorModelText('Please fill required field')
            setErrorModel(true);
        }
    }

    return (
        <React.Fragment>
            <Container fluid >
                <Form style={{ marginTop: "12%" }}>
                    <Row className='my-5 d-flex justify-content-center '>
                        <Col xs={5}>
                            <div>
                                <h3>Change Password</h3>
                            </div>
                           
                            <div>
                                <Label className='my-2'>Enter code</Label>
                                <Input
                                    className='my-2'
                                    id="exampleEmail"
                                    name="code"
                                    placeholder="Enter Enter code"
                                    type="password"
                                    onChange={(e) => setToken(e.target.value)}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>New Password </Label>
                                <Input
                                    className='my-2'
                                    id="exampleEmail"
                                    name="password"
                                    placeholder="Enter New Password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <Label className='my-2'>Confirm New Password</Label>
                                <Input
                                    className='my-2'
                                    id="exampleEmail"
                                    name="password2"
                                    placeholder="Enter Confirm New Password"
                                    type="password"
                                    onChange={(e) => seConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div className='d-grid my-4'>
                                <button className="btn btn-primary" type="button" onClick={()=>changePasswordNow()}>Change My Password</button>
                            </div>
                        </Col>
                    </Row>

                </Form>
                {errorModel === true ? (
                    <SweetAlert
                        title={errorModelText}
                        timeout={2000}
                        style={{
                            position: "absolute",
                            top: "0px",
                            // right: "0px"
                        }}
                        showCloseButton={false}
                        showConfirm={false}
                        error
                        onConfirm={() => setErrorModel(false)}

                    ></SweetAlert>
                ) : (null)}
            </Container>
        </React.Fragment>


    );
}

export default ChangePassword;