import { baseURL } from '../../../src/components/Admin/BaseURL'


export const getFirstTenNotificationRequest = async (accessToken, amount) => {
    try {
        const response = await fetch(
            `${baseURL}/limited-unread-notifications/${amount}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const acceptNotificationRequest = async (accessToken, code, id) => {
    try {
        const response = await fetch(
            `${baseURL}/invite/${code}/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const markReadNotificationRequest = async (accessToken, id) => {
    try {
        const response = await fetch(
            `${baseURL}/notification/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const rejectNotificationRequest = async (accessToken, id) => {
    try {
        const response = await fetch(
            `${baseURL}/notification-reject/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const getAllNotificationRequest = async (accessToken,username) => {
    try {
        const response = await fetch(
            `${baseURL}/notifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const getUnReadNotificationsRequest = async (accessToken, ) => {
    try {
        const response = await fetch(
            `${baseURL}/unread-notifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};
export const getReadNotificationsRequest = async (accessToken, ) => {
    try {
        const response = await fetch(
            `${baseURL}/unread-notifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer" + "  " + accessToken,
                },
            }
        );
        const result = await response.json();
        return result;
    } catch (e) {
        throw e;
    }
};


